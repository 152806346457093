import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import PhoneEnabledOutlinedIcon from '@material-ui/icons/PhoneEnabledOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  medium: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  iconStyle: {
    marginRight: '0.5em',
    verticalAlign: 'top',
    color: 'darkgrey'
  }
}));

export default function ContactInfo({user}) {
  const localStyles = useStyles();
  const {email, mobileNumber, img, name} = user

  return (
      <Grid container spacing={2}>
        {
          img &&
          <Grid item>
            <Avatar src={img} className={localStyles.medium}></Avatar>
          </Grid>
        }
        <Grid item className={!img ? 'pl-md-3':''}>
          <Typography variant="h6" color="inherit"> {name}</Typography>
          <Grid item container>
            <Grid item>
              <PhoneEnabledOutlinedIcon className={localStyles.iconStyle} />
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {mobileNumber}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container >
            <Grid item>
              <EmailOutlinedIcon className={localStyles.iconStyle} />
            </Grid>
            <Grid item>
              <Typography variant="body1">{email}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  )

}
