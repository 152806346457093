import React, {useContext} from "react";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import {AuthContext} from '../shared/Contexts/AuthContext'
import { DEALER_ROLE, VEHICLE_ADDONS, ADMIN_ROLE} from '../shared/Constants'
import Reports from './Dealer/Reports'
import { ImageContainer } from '../shared/ImageContainer'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


export default function VehicleDetails({vehicleDetails, images}) {
  const { role } = useContext(AuthContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <React.Fragment>
        {
          images.length > 0 &&
          <GridList cols={matches ? 3:1} cellHeight={200}>
            {
              images.map((tile, index) => (
                <GridListTile key={index}>
                  <ImageContainer
                    src={tile.src}
                    alt={tile.imageType}/>
                </GridListTile>
              ))
            }
          </GridList>
        }
        {
              (role === DEALER_ROLE || role === ADMIN_ROLE) && <Reports />
        }
        {
          Object.keys(vehicleDetails).length > 0 &&
          <Grid container className="p-5">
            <Grid item xs={12} sm={4}>
              <div className="pb-5">
                <Typography variant="caption" color="textSecondary" component="p">
                  Make
                </Typography>
                <Typography variant="body2" component="p">
                  <strong>{vehicleDetails.make}</strong>
                </Typography>
              </div>
              <div className="pb-5">
                <Typography variant="caption" color="textSecondary" component="p">
                  Model
                </Typography>
                <Typography variant="body2" component="p">
                  <strong>{vehicleDetails.model}</strong>
                </Typography>
              </div>
              <div className="pb-5">
                <Typography variant="caption" color="textSecondary" component="p">
                  Variant
                </Typography>
                <Typography variant="body2" component="p">
                  <strong>{vehicleDetails.vehicleVariant.name}</strong>
                </Typography>
              </div>
              <div className="pb-5">
                <Typography variant="caption" color="textSecondary" component="p">
                  Year
                </Typography>
                <Typography variant="body2" component="p">
                  <strong>{vehicleDetails.year}</strong>
                </Typography>
                      </div>
                      <div className="pb-5">
                          <Typography variant="caption" color="textSecondary" component="p">
                              Odometer
                </Typography>
                          <Typography variant="body2" component="p">
                              <strong>{vehicleDetails.odometer}</strong>
                          </Typography>
                      </div>
                      <div className="pb-5">
                          <Typography variant="caption" color="textSecondary" component="p">
                              Location
                </Typography>
                          <Typography variant="body2" component="p">
                              <strong>{vehicleDetails.location}</strong>
                          </Typography>
                      </div>
            </Grid>
          </Grid>
        }
        {
          !Object.keys(vehicleDetails).length &&
          <Grid container className="p-5">
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" color="textSecondary">
                No vehicle information available
              </Typography>
            </Grid>
          </Grid>
        }
    </React.Fragment>
  );
}
