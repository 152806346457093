import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {StyledDialogTitle} from '../shared/StyledDialogs'


export default function DeleteListingDialog({open, closeDialog, deleteListing, name}) {

  return (
    <Dialog maxWidth='xs' onClose={closeDialog}  open={open}>
      <StyledDialogTitle onClose={closeDialog}>
        Delete Vehicle
      </StyledDialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Do you wish to delete {name}?
        </Typography>
      </DialogContent>
      <DialogActions className="py-3 px-3">
        <Button color="secondary" onClick={closeDialog}>Cancel</Button>
        <Button variant="contained" onClick={deleteListing} color="primary">
          Delete Listing
        </Button>
      </DialogActions>
    </Dialog>
  )

}
