import {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {BASE_URI} from './Constants'
import {AlertContext} from './Contexts/AlertContext'

export default function LocationFilter(query) {
  const { addAlert } = useContext(AlertContext)
  const [loadingLocation, setLoadingLocation] = useState(true)
  const [error, setError] = useState(false)
  const [locations, setLocations] = useState([])

  useEffect(() => {
    let cancel
    setLoadingLocation(true)
    setError(false)
    const params = {
      searchTerm: query
    }
    axios({
      method: 'GET',
      url: `${BASE_URI}/dashboard/location`,
      params,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {
      setLocations(prevLocations => {
        let combinedArray = [...new Set([...prevLocations, ...response.data])]
        const removedDuplicates= combinedArray.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
        return removedDuplicates
      })
      setLoadingLocation(false)
    }).catch( error => {
      if(axios.isCancel(error)) return
      if (error.response) {
        addAlert('Cannot perform query')
        setError(true)
      }
    })
    return () => cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return {loadingLocation, error, locations}
}