import React, { useState, useEffect, useContext } from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core//Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import axios from 'axios/index'
import {BASE_URI} from './Constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import {AlertContext} from './Contexts/AlertContext'

const useStyles = makeStyles((theme) => ({
  TagDefault: {
    padding: ' 5px 10px',
    backgroundColor: '#d8d8d8',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  TagSelected: {
    backgroundColor: '#5287fd',
    color: '#FFF !important'
  }
}));

export function Tags({rating, updateTags}) {
  const classes = useStyles();
  const [reviewTags, setTags] = useState([]);
  const [isLoading, setLoading] = useState(false)
  const { addAlert } = useContext(AlertContext)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (rating > 0) {
      setLoading(true)
      retrieveTags(source.token).then((response) => {
        let tags = response.data.map((tag) => {
          return {
            ...tag,
            status: false
          }
        })
        setTags(tags)
        setLoading(false)
      }).catch((error) => {
        addAlert('Unable to fetch review tags')
      })
    } else {
      setTags([])
    }
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating]);

  const retrieveTags = (cancelToken) => {
    return axios.get(`${BASE_URI}/bid/reviews/tags`, {
      params: {
        rating
      },
      cancelToken
    })
  }

  const addTag =(tag, index) => {
    updateTags(tag)
    let tmpArrayTags = [...reviewTags]
    tmpArrayTags[index] = {
      id: tag.id,
      name: tag.name,
      status: !tag.status
    }
    setTags(tmpArrayTags)
  }

  return (
    <Grid className="my-2" container alignItems='center' spacing={1}>
      {
        reviewTags.map((tag, index) => (
          <Grid item key={tag.id}>
            <div className={clsx(classes.TagDefault, {[classes.TagSelected]: tag.status})} onClick={() => addTag(tag, index)}>
              <Typography className="text-capitalize">
                {tag.name.toLowerCase()}
              </Typography>
            </div>
          </Grid>
        ))
      }
      {
        (isLoading && reviewTags.length === 0) &&
          <Grid item>
            <CircularProgress size={80} />
          </Grid>
      }
    </Grid>
  )
}