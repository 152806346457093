import React, {useEffect, useState, useContext} from "react";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {BASE_URI} from '../../shared/Constants'
import axios from 'axios/index'
import {AlertContext} from '../../shared/Contexts/AlertContext'

const useStyles = makeStyles(() => ({
  dealerSoldHeader: {
    color: '#FFF',
    backgroundColor: '#498d20'
  },
  dealerReviewHeader: {
    color: '#FFF',
    backgroundColor: '#3c761a'
  },
  fadedWhite: {
    color: 'rgba(255,255,255,0.5)'
  },
  tag: {
    padding: ' 5px 10px',
    backgroundColor: 'transparent',
    border: '2px solid white',
    borderRadius: '5px'
  }

}));

export default function ReviewHeader({id}) {
  const localStyles = useStyles();
  const [tags, setTags] = useState([]);
  const [rating, setRating] = useState(null);
  const { addAlert } = useContext(AlertContext)

  useEffect(()=> {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getRating = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/dashboard/rating`, {
          params: {
            vehicleListingId: id
          },
          cancelToken : source.token
        })
        setTags(result.data?.tags)
        setRating(result.data?.rating)
      } catch (error) {
        if (error.response && error.response.data.error !=='Rating was not found') {
          addAlert('Unable to get dealers rating')
        }
      }
    }
     if (id) {
       getRating()
     }
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return(
    <React.Fragment>
      {
        rating &&
        <Grid container justify="space-between" align="center" className={clsx('py-2 px-4',localStyles.dealerReviewHeader)}>
          <Grid item className="d-flex align-items-center">
            <Typography variant="body1" component="p">You were rated by seller</Typography>
          </Grid>
          <Grid item className="d-flex align-items-center">
            <Rating name="read-only"
                    style={{color: 'white'}}
                    className={clsx('pr-2')}
                    value={rating} readOnly
                    emptyIcon={<StarBorderIcon className={localStyles.fadedWhite} fontSize="inherit" />}
            />
            <Grid className="my-2" container alignItems='center' spacing={1}>
              {
                tags.map((tag) => (
                  <Grid item key={tag.id}>
                    <div className={localStyles.tag}>
                      <Typography className="font-weight-light">
                        {tag.name}
                      </Typography>
                    </div>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </Grid>
      }
    </React.Fragment>
  )
}