import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import { StyledDialogTitle} from '../../shared/StyledDialogs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import {CurrencyInput} from '../../shared/TextMask'

export default function EditBidDialog({open, closeDialog, vehicleName, amount, confirm}) {
  const [bidAmount, setBid] = useState(null)
  const [error, setError] = useState(false)

  useEffect(()=> {
    if (amount > 0) {
      setBid(amount)
      setError(false)
    }
  },[amount])

  const handleChange = (event) => {
    if(event.target.value === 'R0' || event.target.value <=0) {
      setError(true)
    } else {
      setError(false)
    }
    setBid(event.target.value)
  }

  return (
    <Dialog maxWidth='xs' onClose={closeDialog}  open={open}>
      <StyledDialogTitle onClose={closeDialog}>
        Edit Bid Amount
      </StyledDialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Do you wish to edit your bid for {vehicleName}? Doing so will notify the seller of the change
          and set status of the bid to Wait-listed.
        </Typography>
        <TextField
          className='mt-2'
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Bid Amount"
          required
          value={bidAmount}
          onChange={handleChange}
          error={error}
          helperText={error ? 'Please enter a valid bid amount' : ' '}
          name="bidAmount"
          id="bidAmount"
          placeholder={'eg. 200 000'}
          InputProps={{
            inputComponent: CurrencyInput,
          }}
        />
      </DialogContent>
      <DialogActions className="py-3 px-3">
        <Button color="secondary" onClick={closeDialog}>Cancel</Button>
        <Button disabled={error}
                variant="contained" onClick={() => confirm(bidAmount)} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )

}
