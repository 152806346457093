import React from 'react';
import Grid from '@material-ui/core/Grid';
import {StyledTab, StyledTabs} from '../../shared/StyledTabs';
import DealerBidsTable from './DealerBidsTable'


export default function BidsContainer() {
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (event,newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Grid item>
      <StyledTabs
        variant="scrollable"
        scrollButtons="on"
        className="mb-4"
        value={tabIndex} onChange={handleTabChange}>
        <StyledTab label="All Bids"/>
        <StyledTab label="Active" />
        <StyledTab label="Accepted" />
        <StyledTab label="Concluded" />
        <StyledTab label="Lost" />
      </StyledTabs>
      {tabIndex === 0 && (<DealerBidsTable />)}
      {tabIndex === 1 && (<DealerBidsTable status={'Waitlisted'}/>)}
      {tabIndex === 2 && (<DealerBidsTable status={'Accepted'}/>)}
      {tabIndex === 3 && (<DealerBidsTable status={'Successful'}/>)}
      {tabIndex === 4 && (<DealerBidsTable status={'Rejected'}/>)}
    </Grid>
  );
}