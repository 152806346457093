import React, {useState, useRef, useContext} from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  BASE_URI,
  PAYMENT_INC_LISTING_TYPE
} from './Constants'
import axios from 'axios'
import {AlertContext} from './Contexts/AlertContext'


const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: '#1794fd',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function PaymentComponent() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const formEl = useRef();
  const [config, setConfig] = useState({
    m1: '',
    m2: '',
    p2: '',
    p3: '',
    p4: '',
    budget: 'N',
    m4: '',
    m5:'',
    m6:'',
    m9: '',
    m10: '',
    m11: ''
  })
  const { addAlert } = useContext(AlertContext)

  const fetchPaymentConfig = (source) => {
    return axios.post(`${BASE_URI}/payment/pending`,{}, {
      params: {
        paymentType: PAYMENT_INC_LISTING_TYPE
      },
      cancelToken : source.token
    })
  }

  const makePayment = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true)
    fetchPaymentConfig(source).then((res) => {
      const data = res.data
      setLoading(false)
      setConfig({
        m1: data.payServiceKey,
        m2: data.softwareVendorKey,
        p2: `${data.id}`,
        p3: `${data.paymentReference}`,
        p4: `${data.amountPayable}`,
        budget: 'N',
        m4: `${data.paymentReference}`,
        m5:'',
        m6:'',
        m9: `${data.email}`,
        m10: '',
        m11: `${data.mobileNumber}`
      })
      formEl.current.submit()
    }).catch(() => {
      setLoading(false)
      addAlert("Unable to get payment Information")
    })
  }

  return (
    <form name="form" ref={formEl}  id="x1" method="POST" action="https://paynow.netcash.co.za/site/paynow.aspx" target="_top">
       <input type="hidden" name="M1" value={config?.m1}/>
       <input type="hidden" name="M2" value={config?.m2}/>
       <input type="hidden" name="P2" value={config?.p2}/>
       <input type="hidden" name="P3"  value={config?.p3}/>
       <input type="hidden" name="P4" value={config?.p4}/>
       <input type="hidden" name="Budget" value={config?.budget}/>
       <input type="hidden" name="M4" value={config?.m4}/>
       <input type="hidden" name="M5" value={config?.m5}/>
       <input type="hidden" name="M6" value={config?.m6}/>
       <input type="hidden" name="M9" value={config?.m9}/>
       <input type="hidden" name="M10" value={config?.m10}/>
       <input type="hidden" name="M11" value={config?.m11}/>
      <div className={classes.wrapper}>
        <Button variant="contained"
                disabled={loading}
                color='primary'
                onClick={() => makePayment()}>Make Payment</Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
     </form>
  )
}
