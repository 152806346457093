import React, { useState, useEffect, useContext } from "react";
import Dialog from '@material-ui/core/Dialog';
import { StyledDialogTitle} from '../../shared/StyledDialogs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import Avatar from '@material-ui/core/Avatar';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import TextField from '@material-ui/core/TextField';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { makeStyles } from '@material-ui/core/styles';
import {Tags} from '../../shared/Tags'
import {CurrencyConverter, CurrencyInput} from '../../shared/TextMask'
import {AuthContext} from '../../shared/Contexts/AuthContext'
import {DEALER_ROLE, SELLER_ROLE} from '../../shared/Constants'

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  }
}));

export default function ConcludeDialog({bidderProp, amount, open, concludeDeal, closeDialog}) {
  const localStyles = useStyles();
  const [selectedTags, setSelectedTags] = useState([]);
  const [finalPrice, setFinalPrice] = useState(null);
  const [rating, setRating] = useState(null);
  const [error, setError] = useState(false)
  const { role } = useContext(AuthContext);
  const [bidder, setBidder] = useState({
    img: '',
    name: ''
  })

  useEffect(() => {
    if (amount && amount > 0) {
      setFinalPrice(amount)
    }
  },[amount])

  useEffect(() => {
    if(bidderProp) {
      setBidder(bidderProp)
    }
  }, [bidderProp])

  const handleClose = () => {
    closeDialog()
  }

  const changeSelectedTags = (updateTag)  => {
    const index = selectedTags.findIndex((tag) => {
      return tag.id === updateTag.id
    });
    const tagArray = Object.assign([],selectedTags)
    if (index !== -1) {
      tagArray.splice(index,1)
    } else {
      tagArray.push(updateTag)
    }
    setSelectedTags(tagArray);
  }

  const handleChange = (event) => {
    if(event.target.value === 'R0' || event.target.value <=0) {
      setError(true)
    } else {
      setError(false)
    }
    setFinalPrice(event.target.value);
  };

  const processConclude = () => {
    const reviewTags = selectedTags.map((tag) => {
      return tag.name
    })
    concludeDeal(CurrencyConverter(finalPrice), rating, reviewTags)
  }

  return (
    <Dialog maxWidth='xs' onClose={handleClose}  open={open}>
      <StyledDialogTitle onClose={handleClose}>
        Conclude Deal
      </StyledDialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          {
            role === DEALER_ROLE ? 'Please enter the final agreed upon price with the seller to conclude the deal.': 'Please rate your experience with the dealer. All active bids will be rejected and the deal will be concluded.'
          }
        </Typography>
        <Grid className="mt-3" container alignItems='center' spacing={2}>
          { role !== DEALER_ROLE &&
            <React.Fragment>
              {bidder.img &&
                <Grid item>
                  <Avatar src={bidder.img} className={localStyles.large}></Avatar>
                </Grid>
              }
              <Grid item>
                <Typography variant="h6">{bidder.name ? bidder.name: bidder.email}</Typography>
                <Rating
                  name="rateDealer" size="large"
                  value={rating}
                  precision={0.5}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />
              </Grid>
            </React.Fragment>
          }
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="finalPrice"
              label="Final Price"
              value={finalPrice}
              placeholder={'eg. 200 000'}
              onChange={handleChange}
              error={error}
              helperText={error ? 'Please enter a valid amount' : ' '}
              InputProps={{
                inputComponent: CurrencyInput
              }}
            />
          </Grid>
          {role !== DEALER_ROLE &&
            <Grid item>
              <Tags updateTags={changeSelectedTags} rating={rating}/>
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions className="py-3 px-3">
        <Button color="secondary" onClick={handleClose}>Cancel</Button>
        <Button disabled={error || (role === SELLER_ROLE && (rating <= 0 || selectedTags.length === 0))}
                variant="contained"
                onClick={processConclude} color="secondary">
          Deal Successful
        </Button>
      </DialogActions>
    </Dialog>
  )

}

// || (role === SELLER_ROLE && (rating <= 0 || selectedTags.length === 0))