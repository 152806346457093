import React, {useContext, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {AuthContext} from '../shared/Contexts/AuthContext'
import {AlertContext} from '../shared/Contexts/AlertContext'
import {LoadingContext} from '../shared/Contexts/LoadingContext'
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import logo from './HomePage/logo.png';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import Slide from '@material-ui/core/Slide';
import {BASE_URI} from '../shared/Constants'
import axios from 'axios'
import Balk from './HomePage/mage.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{color:"#009639"}}>
      {'Copyright © '}
      <Link color="inherit" component={RouterLink} to='/'>
        Avo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    
  },
  image: {
    backgroundImage: `url(${Balk})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'right bottom',
    height: '100vh'
  },
  paper: {
    flexDirection: 'column',
    padding: '15px 40px 30px 40px',
    borderRadius: '10px',
    display: 'flex',
    maxWidth: '500px',
    backgroundColor: 'white',
    alignItems: 'center',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    [theme.breakpoints.down("md")]: {
      padding: '30px'
    }
  },
  loginContainer: {
    backgroundColor: 'transparent',
    overflow: 'hidden',
    display: 'flex',
    padding: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down("md")]: {
      padding: '20px'
    }
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    background:'#009639',
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    color: '#3f51b5 !important',
    '&:hover': {
      textDecoration: 'underline !important'
    }
  }
}));

export default function LoginComponent(props) {
    const classes = useStyles();
    let location = useLocation();
  const { login, setSession } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const [credentials, setValues] = useState({
    username: '',
    password: ''
  });
  const [errorUsername, setErrorUsername] = useState(false)
  const [errorPassword, setErrorPassword] = useState(false)
  const [loginAnimation, setLoginAnimation] = useState(true)
  const [resetAnimation, setResetAnimation] = useState(false)


  const handleChange = (prop) => (event) => {
     if (prop === 'username') {
       setErrorUsername(false)
     }
     if (prop === 'password') {
       setErrorPassword(false)
     }
    setValues({ ...credentials, [prop]: event.target.value });
  };

  const loginUser = (event) => {
    event.preventDefault();
    showLoading()
    login(credentials.username,credentials.password).then((response) => {
      hideLoading()
        setSession(credentials.username, response.data.token, response.data.refreshToken)

        if (location && location.state) {
            props.history.push(location.state.from);
        } else {
            props.history.push(`/myVehicles`);
        }
      
    }).catch((error) => {
      hideLoading()
      if(error.response && error.response.data) {
        addAlert(error.response.data)
      } else {
        addAlert('Incorrect email or password')
      }
    })
  }

  const validateUsername = () => {
    return setErrorUsername(credentials.username ==='' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(credentials.username))
  }

  const validatePassword = () => {
    return setErrorPassword(credentials.password === '' || credentials.password === null)
  }

  const resetPasswordRequest = (event) => {
    event.preventDefault();
    if (!errorUsername) {
      showLoading()
      processResetPasswordRequest().then(() => {
        hideLoading()
        addAlert('Please check your email inbox', 10000)
        setResetAnimation((prev) => !prev)
      }).catch((error) => {
        hideLoading()
        if (error.response?.data.error) {
          addAlert(error.response?.data.error)
        } else {
          addAlert('Something went wrong!')
        }
      })
    } else {
      addAlert('Something went wrong!')
    }
  }

  const processResetPasswordRequest = () => {
    return axios.post(`${BASE_URI}/Identity/ForgotPasswordRequest`, {
      email: credentials.username
    })
  }

  return(
    <Grid container component="main" className={classes.image}>
        <Grid item xs={12} sm={12} md={12}
              className={classes.loginContainer}
              component={Paper} elevation={0} square>
          <div className={classes.paper}>
            <Link variant="button"
                  component={RouterLink} to='/'
                  className="flex-grow-1">
              <img src={logo} style={{maxWidth: '250px'}} alt="Avo"/>
            </Link>
            <Typography component="h1" variant="h5" style={{fontWeight: '400 !important'}} className="py-3">
              Sign in
            </Typography>
          
            <div style={{minHeight: 240, width: '100%'}}>
              <Slide direction="left" in={loginAnimation} mountOnEnter unmountOnExit
                     onExited={() => setResetAnimation((prev) => !prev)}>
                <form className={classes.form} onSubmit={loginUser} noValidate>
                  <TextField
                    fullWidth
                    required
                    margin="normal"
                    id="username"
                    autoFocus
                    label="Email Address"
                    value={credentials.username}
                    onChange={handleChange('username')}
                    onBlur={validateUsername}
                    error={errorUsername}
                    helperText={errorUsername ? 'Requires valid email address': ''}
                  />
                  <TextField
                    fullWidth
                    required
                    margin="normal"
                    type='password'
                    id="password"
                    label="Password"
                    value={credentials.password}
                    onChange={handleChange('password')}
                    onBlur={validatePassword}
                    error={errorPassword}
                    helperText={errorPassword ? 'Requires password': ''}
                  />
                  <Button disabled={errorUsername || errorPassword || credentials.username === '' || credentials.password === ''}
                          fullWidth
                          className={classes.submit}
                          type="submit" variant="contained" color="primary">
                    Login
                  </Button>
                </form>
              </Slide>

              <Slide direction="left" in={resetAnimation}
                     onExited={() => setLoginAnimation((prev) => !prev)}
                     mountOnEnter unmountOnExit>
                <form className={classes.form} onSubmit={resetPasswordRequest} noValidate>
                  <TextField
                    fullWidth
                    required
                    margin="normal"
                    id="username"
                    autoFocus
                    label="Email Address"
                    value={credentials.username}
                    onChange={handleChange('username')}
                    onBlur={validateUsername}
                    error={errorUsername}
                    helperText={errorUsername ? 'Requires email address': ''}
                  />
                  <Button disabled={errorUsername || credentials.username === ''}
                          fullWidth
                          className={classes.submit}
                          type="submit" variant="contained" color="primary">
                    Reset Password
                  </Button>
                </form>
              </Slide>
            </div>

            <Grid container>
              <Grid item xs={6}>
                <Link
                  className={classes.link}
                  style={{display: loginAnimation ? 'block': 'none'}}
                  onClick={()=> setLoginAnimation((prev) => !prev)} variant="body2">
                  Forgot Password?
                </Link>
                <Link
                  className={classes.link}
                  style={{display: resetAnimation ? 'block': 'none'}}
                  onClick={()=> setResetAnimation((prev) => !prev)} variant="body2">
                  Back to Login
                </Link>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Copyright />
            </Box>
        </div>
      </Grid>
    
  </Grid>
  )
}
