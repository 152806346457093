import React, {useState, useContext}  from "react";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from '@material-ui/core/styles/index'
import {CurrencyValue} from '../shared/TextMask'
import moment from 'moment'
import {ADMIN_ROLE, PAGE_SIZE} from '../shared/Constants'
import TableContainer from '@material-ui/core/TableContainer'
import TableHeadersSort from '../shared/TableHeadersSort'
import TablePagination from '@material-ui/core/TablePagination'
import SellerBidsFilter from '../shared/SellerBidsFilter'
import CircularProgress from '@material-ui/core/CircularProgress'
import {BidContext} from '../shared/Contexts/BidContext'
import {AuthContext} from '../shared/Contexts/AuthContext'
import GlobalStyles from '../GlobalStyles'
import clsx from 'clsx'
import {LoadingContext} from '../shared/Contexts/LoadingContext'
import {AlertContext} from '../shared/Contexts/AlertContext'

const useStyles = makeStyles(() => ({
  tableHeader: {
    borderBottom: '2px solid darkgray'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}));

export default function SellerBidsTable({id, acceptBid}) {
  const localStyles = useStyles();
  const globalStyles = GlobalStyles();
  const { role } = useContext(AuthContext);
  const headCells = [
    { id: 'rating', numeric: false, width: '30%', label: 'Bidder Rating', sort: true },
    { id: 'dateOfBid', numeric: false, label: 'Date of Bid', sort: true },
    { id: 'bid', numeric: false, label: 'Bid', sort: true },
    { id: 'action', numeric: false, label: ' ', sort: false },
  ];
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const { withdrawBid: withdrawBidRequest  } = useContext(BidContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { addAlert } = useContext(AlertContext)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('dateOfBid');
  const{
    loading,
    bids,
    total
  }= SellerBidsFilter(id, page, pageSize, order, orderBy)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const deleteBid = (bidId) => {
    if (bidId) {
      showLoading()
      withdrawBidRequest(bidId).then(() => {
        hideLoading()
        setPage(0)
        addAlert(`Deleted Bid ${bidId}`)
      }).catch(() => {
        hideLoading()
        addAlert(`Unable to delete bid ${bidId}`)
      })
    } else {
      addAlert('Bid ID is required')
    }
  }

  return (
    <TableContainer>
      <Table aria-label="bids table" size="small">
        <TableHeadersSort
          classes={localStyles}
          order={order}
          orderBy={orderBy}
          headCells={headCells}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {bids.map((row) => (
            <TableRow key={row.bidder.bidderId}>
              <TableCell>
                <List dense>
                  <ListItem dense>
                    <ListItemAvatar>
                      <Avatar src={row.bidder ? row.bidder.bidderImage: ''}>
                      </Avatar>
                    </ListItemAvatar>
                      <ListItemText primary={
                        <Rating name="read-only" value={row.bidder.rating} readOnly />
                      }
                      />
                  </ListItem>
                </List>
              </TableCell>
              <TableCell align="left">{moment(row.dateOfBid).format('D MMM, YYYY')}</TableCell>
              <TableCell align="left"><CurrencyValue value={row.bid} /></TableCell>
              <TableCell align="right" className="pr-0" >
                <Button variant="contained"
                        onClick={() => acceptBid(row.id)}
                        color="secondary">
                  Accept Bid
                </Button>
                {
                  role === ADMIN_ROLE &&
                    <Button variant="contained"
                            className={clsx('ml-2', globalStyles.buttonRed)}
                            onClick={() => deleteBid(row.id)}
                            color="primary">
                      Delete Bid
                    </Button>
                }
              </TableCell>
            </TableRow>
          ))}
          {
            (!bids.length && !loading) &&
            <TableRow>
              <TableCell colSpan={4} align="center"
                         style={{borderBottom: 0}}
                         className="py-3">
                <Typography variant="h6" color="textSecondary">
                  No current bids
                </Typography>
              </TableCell>
            </TableRow>
          }
          {
            loading &&
            <TableRow>
              <TableCell colSpan={4} align="center"
                         style={{borderBottom: 0}}
                         className="py-3">
                <CircularProgress />
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={total}
        page={page}
        rowsPerPageOptions={[5,10,25]}
        onChangePage={handleChangePage}
        rowsPerPage={pageSize}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}