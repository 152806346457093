import React, { useContext, useState, useEffect } from "react";
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import PersonalDetails from '../PersonalDetails'
import GlobalStyles from '../../GlobalStyles'
import CardActions from '@material-ui/core/CardActions'
import VehicleDetails from '../VehicleDetails'
import {BASE_URI} from '../../shared/Constants'
import axios from 'axios'
import {AlertContext} from '../../shared/Contexts/AlertContext'
import {LoadingContext} from '../../shared/Contexts/LoadingContext'
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader'


export default function ConfirmationContainer({onSubmit, id}) {
  const classes = GlobalStyles()
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { addAlert } = useContext(AlertContext)
  const [vehicleInfo, setVehicleInfo] = useState({})
  const [userInfo, setUserInfo] = useState({})
  const [isLoading, setLoading] = useState(true)


  useEffect(() => {
    if (!id) {
      addAlert('Missing details')
      setLoading(false)
    } else {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const getVehicleDetails = async () => {
          try {
            const result = await fetchVehicleDetails(source)
            setVehicleInfo(result.data)
          } catch (error) {
            if (error.response) {
              addAlert('Error fetching vehicle details')
              setLoading(false)
            }
          }
        }
        const getPersonalDetails = async () => {
          try {
            const result = await fetchPersonalDetails(source)
            setUserInfo(result.data)
          } catch (error) {
            if (error.response) {
              addAlert('Error fetching personal details')
              setLoading(false)
            }
          }
        }
        getVehicleDetails()
        getPersonalDetails().then(() => {
          setLoading(false)
        })
      return () => {
        source.cancel();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchVehicleDetails = (source) => {
    return axios.get(`${BASE_URI}/vehicle`, {
      params: {
        vehicleListingId: id
      },
      cancelToken : source.token
    })
  }

  const fetchPersonalDetails = (source) => {
    return axios.get(`${BASE_URI}/user`, {
      cancelToken : source.token
    })
  }

  const confirmDetails = () => {
    if (id != null) {
      showLoading()
      confirmPost().then(() => {
        hideLoading()
          addAlert('Your vehicle has been submitted, one of our agents will contact you shortly')
        onSubmit()
      }).catch(() => {
        hideLoading()
        addAlert('Error confirming your vehicle post')
      })
    } else {
      addAlert('Missing vehicle ID')
    }
  }

  const confirmPost =() => {
    return axios.post(`${BASE_URI}/vehicle/confirm`, null,  {
      params: {
        vehicleListingId: id
      }
    })
  }

  return (
    <Card className="d-flex justify-content-between flex-column">
      <CardHeader className="p-4" title={'Please confirm your details are correct'}/>
      {
        !isLoading &&
          <React.Fragment>
            <CardContent>
              <VehicleDetails vehicleDetails={vehicleInfo ? vehicleInfo:{}}
                              images={vehicleInfo.imgArr ? vehicleInfo.imgArr: {}} />
              <Divider/>
              <PersonalDetails details={userInfo} />
            </CardContent>
            <CardActions className={classes.greyBackground}>
              <Grid container justify="flex-end" alignItems="center">
                <Button  variant='contained' onClick={confirmDetails} color="primary">
                  Confirm
                </Button>
              </Grid>
            </CardActions>
          </React.Fragment>
      }
      {
        isLoading &&
        <CardContent>
          <Grid container justify="center" align="center" className="p-5">
            <Grid item>
              <CircularProgress size={80} />
            </Grid>
          </Grid>
        </CardContent>
      }
    </Card>
  );
}