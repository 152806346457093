import React, { useEffect, useState, useContext, useRef } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import GlobalStyles from '../../GlobalStyles';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles'
import { StyledRadio } from '../../shared/StyledRadio'
import ImageService from '../../shared/ImageService'
import ImageUploader from '../../shared/ImageUploader'
import { BASE_URI, IMAGE_UPLOADER_CONFIG, LICENSE_IMAGE_UPLOADER_CONFIG, VEHICLE } from '../../shared/Constants'
import axios from 'axios';
import { AlertContext } from '../../shared/Contexts/AlertContext'
import { LoadingContext } from '../../shared/Contexts/LoadingContext'
import Resizer from 'react-image-file-resizer';


const useStyles = makeStyles(() => ({
    legend: {
        fontSize: 13
    },
    radioButton: {
        color: '#0185fd !important'
    },
    labels: {
        fontSize: 14,
        color: 'black'
    }
}))

export default function VehicleSpecsForm({ vehicleId, vehicle, onSubmit }) {
    const { addAlert } = useContext(AlertContext)
    const mountedRef = useRef(true)
    const { showLoading, hideLoading } = useContext(LoadingContext)
    const classes = GlobalStyles();
    const localStyles = useStyles();
    const [values, setValues] = React.useState({
        vehicleVariantId: '',
        make: '',
        model: '',
        year: '',
        makeId: null,
        modelId: null,
        category: ''
    });
    const [errors, setError] = React.useState({
        variant: false,
        make: false,
        model: false,
        year: false,
        category: false
    })
    const [disabled, setDisabled] = React.useState({
        variant: true,
        model: true,
        year: true,
        make: true
    })
    const [vehicleMakes, setVehicleMakes] = React.useState([])
    const [vehicleCategories, setVehicleCategories] = React.useState([])
    const [vehicleModels, setVehicleModels] = React.useState([])
    const [years, setYears] = React.useState([])
    const [selectedModelId, setSelectedModelId] = React.useState('')
    const [variant, setVariant] = React.useState('')
    const [variantsArr, setVariantArr] = React.useState([])
    const [activeMake, setMakeResult] = useState(null)
    const [activeModel, setModelResult] = useState(null)

    function compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    useEffect(() => {
        if (vehicleCategories) {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            let tmpVehicle = { ...values };
            tmpVehicle.category = vehicleCategories[0]?.typeName;
            setValues(tmpVehicle);

            const getMakes = async () => {
                try {
                    var makesResult = await axios.get(`${BASE_URI}/vehicle/make`, {
                        params: {
                            vehicleTypeId: vehicleCategories[0]?.id,
                        },
                        cancelToken: source.token
                    })

                    setVehicleMakes(makesResult?.data.sort(compareValues('manufacturerName')))
                    setDisabled({
                        variant: true,
                        model: false,
                        year: true,
                        make: false
                    })
                } catch (error) {
                    if (axios.isCancel(error)) return;
                    addAlert(`Unable to fetch the Makes for ${vehicleCategories[0]?.typeName}`);
                }
            }

            getMakes();

        }
    }, [vehicleCategories])

    useEffect(() => {
        if (activeMake) {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            let tmpVehicle = { ...values }
            tmpVehicle.make = activeMake.name
            tmpVehicle.makeId = activeMake.id
            tmpVehicle.model = ''
            tmpVehicle.year = ''
            setVariant('')
            setVariantArr([])
            setYears([])
            setValues(tmpVehicle)
            const getModels = async () => {
                try {
                    var modelsResult = await axios.get(`${BASE_URI}/Vehicle/model`, {
                        params: {
                            vehicleMakeId: activeMake.id,
                        },
                        cancelToken: source.token
                    })


                    setVehicleModels(modelsResult.data.sort(compareValues('modelName')))
                    let tmpDisabled = { ...disabled }
                    tmpDisabled.model = false
                    tmpDisabled.year = true
                    tmpDisabled.variant = true
                    setDisabled(tmpDisabled)
                } catch (error) {
                    if (axios.isCancel(error)) return;
                    addAlert(`Unable to fetch the models for ${activeMake.name}`);
                }
            }

            getModels();
        }
    }, [activeMake])

    useEffect(() => {
        if (activeModel) {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            let tmpVehicle = { ...values };
            tmpVehicle.model = activeModel?.name
            tmpVehicle.modelId = activeModel?.id;
            setValues(tmpVehicle);

            let tmpDisabled = { ...disabled }
            tmpDisabled.model = false
            tmpDisabled.year = false
            tmpDisabled.variant = true
            setDisabled(tmpDisabled)

            const getYears = async () => {
                try {
                    var modelsResult = await axios.get(`${BASE_URI}/vehicle/year`, {
                        params: {
                            modelId: activeModel?.id,
                        },
                        cancelToken: source.token
                    })

                    setYears(modelsResult?.data)
                    let tmpDisabled = { ...disabled }
                    tmpDisabled.year = false
                    tmpDisabled.variant = true
                    setDisabled(tmpDisabled)
                } catch (error) {
                    if (axios.isCancel(error)) return;
                    addAlert(`Unable to fetch the years for ${activeModel?.name}`);
                }
            }

            getYears();
        }
    }, [activeModel])

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const getCategories = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/vehicle/type`, {
                    cancelToken: source.token
                })
                setVehicleCategories(result.data)
            } catch (error) {
                addAlert('Unables to load vehicle categories')
            }
        }

        getCategories().then(() => {
            if (!mountedRef.current) return null
        })
        return () => {
            mountedRef.current = false
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if (vehicle) {
            setDisabled({
                variant: false,
                model: false,
                year: false,
                make: false
            })
            setSelectedModelId(vehicle.modelId)

            const getMake = async () => {
                try {
                    const result = await axios.get(`${BASE_URI}/vehicle/make`, {
                        params: {
                            vehicleTypeId: vehicle.vehicleTypeId
                        },
                        cancelToken: source.token
                    })
                    setVehicleMakes(result.data.sort(compareValues('manufacturerName')))
                } catch (error) {
                    addAlert('Unable to load vehicle makes')
                }
            }

            const getModel = async () => {
                try {
                    const result = await axios.get(`${BASE_URI}/Vehicle/model`, {
                        params: {
                            vehicleMakeId: vehicle.makeId,
                        },
                        cancelToken: source.token
                    })
                    setVehicleModels(result.data.sort(compareValues('modelName')))
                } catch (error) {
                    if (error.response) {
                        addAlert(`Unable to fetch the models for ${vehicle.make}`)
                    }
                }
            }

            const getYears = async () => {
                try {
                    const result = await axios.get(`${BASE_URI}/vehicle/year`, {
                        params: {
                            modelId: vehicle.modelId
                        },
                        cancelToken: source.token
                    })
                    setYears(result.data)
                } catch (error) {
                    if (error.response) {
                        addAlert(`Unable to fetch the years for ${vehicle.model}`)
                    }
                }
            }

            const getVariants = async () => {
                try {
                    const result = await axios.get(`${BASE_URI}/Vehicle/variant`, {
                        params: {
                            modelId: vehicle.modelId,
                            year: vehicle.year,
                        },
                        cancelToken: source.token
                    })
                    setVariantArr(result.data)
                    const tmpVariants = result.data.filter((obj) => obj.id === vehicle.vehicleVariantId)
                    setVariant(tmpVariants[0].name)
                } catch (error) {
                    if (error.response) {
                        addAlert(`Unable to fetch variants for ${vehicle.year}`)
                    }
                }
            }
            let finalResult = (async () => {
                await Promise.all([getMake(), getModel(), getYears(), getVariants()])
            })

            finalResult().then(() => {
                setValues(vehicle)
            }).catch((error) => {
                addAlert('Unable to edit vehicle')
            })

        } else {
            setValues({
                vehicleVariantId: '',
                make: '',
                model: '',
                year: '',
                makeId: null,
                modelId: null,
                category: ''
            })
        }

        return () => {
            mountedRef.current = false
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicle])

    const fetchModel = (id, cancelToken) => {
        return axios.get(`${BASE_URI}/Vehicle/model`, {
            params: {
                vehicleMakeId: id,
            },
            cancelToken
        })
    }

    const fetchMake = (vehicleTypeId, cancelToken) => {
        return axios.get(`${BASE_URI}/vehicle/make`, {
            params: {
                vehicleTypeId
            },
            cancelToken
        })
    }

    const fetchYears = (modelId, cancelToken) => {
        return axios.get(`${BASE_URI}/vehicle/year`, {
            params: {
                modelId
            },
            cancelToken
        })
    }

    const fetchVariants = (year, modelId, cancelToken) => {
        return axios.get(`${BASE_URI}/Vehicle/variant`, {
            params: {
                modelId: selectedModelId ? selectedModelId : (activeModel?.id ? activeModel?.id : modelId),
                year: year,
            },
            cancelToken
        })
    }


    const handleCategoryChange = (event, child) => {
        const id = child.props.id
        setVariant('')
        setVariantArr([])
        setYears([])
        setVehicleMakes([])
        let tmpVehicle = { ...values }
        tmpVehicle.category = event.target.value
        tmpVehicle.make = ''
        tmpVehicle.makeId = ''
        tmpVehicle.model = ''
        tmpVehicle.year = ''
        setValues(tmpVehicle)
        fetchMake(id).then((response) => {
            setVehicleMakes(response.data.sort(compareValues('manufacturerName')))
            setDisabled({
                variant: true,
                model: true,
                year: true,
                make: false
            })
        }).catch((error) => {
            addAlert(`Unable to fetch the makes for ${event.target.value}`)
        })
    }

    const handleMakeChange = (event, child) => {
        const id = child.props.id
        let tmpVehicle = { ...values }
        tmpVehicle.make = event.target.value
        tmpVehicle.makeId = child.props.id
        tmpVehicle.model = ''
        tmpVehicle.year = ''
        setVariant('')
        setVariantArr([])
        setYears([])
        setValues(tmpVehicle)
        fetchModel(id).then((response) => {
            setVehicleModels(response.data.sort(compareValues('modelName')))
            let tmpDisabled = { ...disabled }
            tmpDisabled.model = false
            tmpDisabled.year = true
            tmpDisabled.variant = true
            setDisabled(tmpDisabled)
        }).catch((error) => {
            addAlert(`Unable to fetch the models for ${event.target.value}`)
        })
    }

    const handleModelChange = (event, child) => {
        const id = child.props.id
        let tmpVehicle = { ...values }
        tmpVehicle.model = event.target.value
        tmpVehicle.modelId = id
        tmpVehicle.year = ''
        setVariant('')
        setVariantArr([])
        setValues(tmpVehicle)
        setSelectedModelId(id)
        setDisabled({ ...disabled, year: false })
        fetchYears(id).then((response) => {
            setYears(response.data)
            let tmpDisabled = { ...disabled }
            tmpDisabled.year = false
            tmpDisabled.variant = true
            setDisabled(tmpDisabled)
        }).catch((error) => {
            addAlert(`Unable to fetch the years for ${event.target.value}`)
        })
    }

    const handleYearChange = (event) => {
        setValues({ ...values, year: event.target.value })
        setDisabled({ ...disabled, variant: false })
        setVariant('')
        fetchVariants(event.target.value).then((response) => {
            setVariantArr(response.data)
            setDisabled({ ...disabled, variant: false })
        }).catch((error) => {
            addAlert(`Unable to fetch variants for ${values.year}`)
        })
    }

    const handleVariantChange = (event, child) => {
        const id = child.props.id;
        const index = variantsArr.findIndex((variant) => {
            return variant.id === id
        });
        setVariant(event.target.value)

        let copyValues = { ...values }
        copyValues.vehicleVariantId = id

        setValues(copyValues)
    }

    const submitForm = () => {
        let errors = validateForm()
        if (!errors) {
            showLoading()
            if (vehicleId) {
                editCar().then((response) => {
                    hideLoading()
                    addAlert('Updated vehicle details')
                    onSubmit(response.data, values)
                }).catch((error) => {
                    hideLoading()
                    addAlert('Error updating vehicle')
                })
            } else {

                localStorage.setItem(VEHICLE, JSON.stringify({
                    vehicleVariantId: values.vehicleVariantId,
                    year: values.year
                }))

                hideLoading()
                addAlert('Saved vehicle details')
                onSubmit(null, values)
            }
        }
    }

    const editCar = () => {

        return axios.put(`${BASE_URI}/vehicle`, {
            vehicleVariantId: values.vehicleVariantId,
            year: values.year
        }, {
            params: {
                vehicleListingId: vehicleId
            }
        })
    }

    const validateForm = () => {
        const requiredFields = Object.fromEntries(
            Object.entries(values).filter(
                ([key, val]) => key !== 'color' && typeof val !== 'boolean'
            ))

        const newErrors = Object.fromEntries(
            Object.entries(requiredFields).map(
                ([key, val]) => {
                    if (key === 'vehicleVariantId' && val === '') {
                        return ['variant', true]
                    } else {
                        return [key, val === '']
                    }
                }
            )
        );
        setError(newErrors)
        return Object.values(newErrors).includes(true)
    }

    const checkError = (prop) => (event) => {
        if (event.target.value === '') {
            setError({ ...errors, [prop]: true })
        } else {
            setError({ ...errors, [prop]: false })
        }
    }

    return (
        <Card>
            <CardHeader className="p-4" title={'About your vehicle'} subheader={'This process will take under 5 mins from start to completion.'} />
            
            <form noValidate autoComplete="off">
                <CardContent className="px-5">

                    <Grid container spacing={4}>

                        <Grid item xs={12} sm={4}>

                            {/*@TODO categories*/}
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                id="category"
                                className='mb-2'
                                select
                                required
                                placeholder="Please select"
                                label="Category"
                                value={values.category}
                                helperText={errors.category ? 'Please select category of the vehicle' : ' '}
                                error={errors.category}
                                onBlur={checkError('category')}
                                onChange={(event, child) => handleCategoryChange(event, child)}
                            >
                                {vehicleCategories.map((option) => (
                                    <MenuItem key={option.id} id={option.id} value={option.typeName}>
                                        {option.typeName}
                                    </MenuItem>
                                ))}
                            </TextField>


                            {/*@TODO Make*/}
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                id="make"
                                className='mb-2'
                                select
                                required
                                placeholder="Please select"
                                label="Make"
                                disabled={disabled.make}
                                value={values.make}
                                helperText={errors.make ? 'Please select the make of the vehicle' : ' '}
                                error={errors.make}
                                onBlur={checkError('make')}
                                onChange={(event, child) => handleMakeChange(event, child)}
                            >
                                {vehicleMakes.map((option) => (
                                    <MenuItem key={option.id} id={option.id} value={option.manufacturerName}>
                                        {option.manufacturerName}
                                    </MenuItem>
                                ))}
                            </TextField>

                            {/*@TODO Model*/}
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                id="model"
                                className='mb-2'
                                select
                                required
                                disabled={disabled.model}
                                placeholder="Please select"
                                label="Model"
                                value={values.model}
                                helperText={errors.model ? 'Please select the model of the vehicle' : ' '}
                                error={errors.model}
                                onBlur={checkError('model')}
                                onChange={(event, child) => handleModelChange(event, child)}
                            >
                                {vehicleModels.map((option) => (
                                    <MenuItem key={option.id} id={option.id} value={option.modelName}>
                                        {option.modelName}
                                    </MenuItem>
                                ))}
                            </TextField>

                            {/*@TODO Year*/}
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="mb-2"
                                fullWidth
                                id="year"
                                select
                                required
                                disabled={disabled.year}
                                placeholder="Please select"
                                label="Year"
                                value={values.year}
                                helperText={errors.year ? 'Please select the year of the vehicle' : ' '}
                                error={errors.year}
                                onBlur={checkError('year')}
                                onChange={handleYearChange}
                            >
                                {years.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>

                            {/*@TODO Variant*/}
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                id="variant"
                                className='mb-2'
                                select
                                required
                                disabled={disabled.variant}
                                placeholder="Please select"
                                label="Variant"
                                value={variant}
                                helperText={errors.variant ? 'Please select the variant of the vehicle' : ' '}
                                error={errors.variant}
                                onBlur={checkError('variant')}
                                onChange={(event, child) => handleVariantChange(event, child)}
                            >
                                {variantsArr.map((option) => (
                                    <MenuItem key={option.id} id={option.id} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>

                </CardContent>
                <CardActions className={classes.greyBackground}>
                    <Grid container justify="flex-end">
                        <Button onClick={submitForm} variant='contained' color="primary">
                            Next
            </Button>
                    </Grid>
                </CardActions>
            </form>
        </Card>
    );
}
