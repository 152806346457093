import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  checked: {
    color: '#0185fd !important'
  },
  track: {
    backgroundColor: '#7fcefd !important',
    opacity: 1
  }
}))


export const StyledRadio = (props) => {
  const { title, leftLabel, rightLabel, handleChange, value } = props;
  const localStyles = useStyles();
  return (
    <FormControl>
      <FormHelperText>{title}</FormHelperText>
      <Grid item container alignItems="center" spacing={1}>
        <Grid item>
          {leftLabel ? leftLabel:'No'}
        </Grid>
        <Grid item>
          <FormControlLabel
            style={{margin: 0}}
            control={
              <Switch checked={value}
                      color="primary"
                      classes={{checked: localStyles.checked, track : localStyles.track}}
                      onChange={handleChange}
                      name='spareKeys' />}
          />
        </Grid>
        <Grid item>{rightLabel ? rightLabel:'Yes'}</Grid>
      </Grid>
    </FormControl>
  );
};