import React, {useContext, useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import CarCarousel from './CarCarousel'
import {CurrencyValue} from './TextMask'
import moment from 'moment';
import {AuthContext} from './Contexts/AuthContext'
import {SELLER_ROLE} from './Constants'

const useStyles = makeStyles({
  stateTag: {
    position: 'absolute',
    left: '16px',
    top: '16px',
    padding: '1px 10px',
    backgroundColor: 'grey',
    borderRadius: '10px',
    color: '#FFF',
    zIndex: '10',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
  },
  green: {
    backgroundColor: '#6ecf1f'
  },
  blue: {
    backgroundColor: '#1794fd'
  },
  active: {
    backgroundColor: '#f29724'
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  cardContainer: {
    width: '100%',
    display: 'block',
    margin: 0,
    position: 'relative',
    userSelect: 'none',
    outline: 0,
    padding: 0
  }
});

export default function CarCard({vehicle, openVehicle}) {
  const classes = useStyles();
  const { role } = useContext(AuthContext);
  const length = vehicle?.addOns?.length;
  const [imgArr, setImgArr] = useState([])

  useEffect(() => {
    if (vehicle?.imgArr.length > 0) {
      const sortedArr = vehicle.imgArr.reduce((acc, element) => {
        if (element.imageType === 'Thumbnail') {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      setImgArr(sortedArr)
    }
  },[vehicle])

  return (
    <Card>
      <div className={classes.cardContainer}>
        {
          role === SELLER_ROLE &&
          <div className={clsx([classes.stateTag],
            {[classes.green]:vehicle.state === 'Sold'},
            {[classes.blue]: vehicle.state === 'Concluding'},
            {[classes.active]: vehicle.state === 'Active'}
          )}>
            <Typography component="span">{vehicle.state}</Typography>
          </div>
        }
        <Grid container>
          <Grid item xs={12} sm={4}
                style={{minHeight: '200px'}}
                className={clsx({
            "d-flex justify-content-center align-items-center": vehicle.imgArr?.length === 0
          })}>
            {
              (vehicle.imgArr && vehicle.imgArr?.length > 0) &&
                <CarCarousel imgArray={imgArr} vehicle={vehicle} openVehicle={openVehicle} />
            }
            {
              (vehicle.imgArr && vehicle.imgArr?.length === 0) &&
              <Typography component="span"
                          className="font-weight-bold"
                          variant="body2" color="textSecondary">No images uploaded</Typography>
            }
          </Grid>
          <Grid item xs={12} sm={8}>
            <CardActionArea style={{height:'100%'}} onClick={()=>openVehicle(vehicle.id)}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={9}>
                    <Typography gutterBottom variant="h6">
                      <strong>{vehicle.name}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="body1" gutterBottom component="div">
                      {
                        vehicle.highestBid > 0 &&  <div className='d-flex justify-content-sm-end' >
                                                <span className="pr-1">Highest bid </span>
                                                <strong><CurrencyValue value={vehicle.highestBid} /></strong>
                                                </div>
                      }
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={9}>
                    <Typography variant="body2" gutterBottom >
                      {vehicle.location}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {
                      vehicle.bidCount > 0 && <Typography  variant="body2" gutterBottom component="div">
                                                <div className='d-flex justify-content-sm-end' >
                                                  {vehicle.bidCount} {vehicle.bidCount > 1 ? 'bids': 'bid'}
                                                </div>
                                              </Typography>
                    }
                  </Grid>
                </Grid>
                <Typography variant="body2" gutterBottom component="p">
                  {vehicle.odometer} km
                </Typography>
                <Typography variant="body2" gutterBottom  component="p">
                  Added on {moment( vehicle.dateAdded).format('D MMM, YYYY')}
                </Typography>
                <Typography component="div" gutterBottom style={{display: 'flex'}} className={classes.capitalizeText} variant="body2" >
                  {
                    vehicle.addOns && vehicle.addOns.map((addOn, index) => {
                      if (length === index +1) {
                        return (
                          <div key={index}>{addOn}</div>
                        )
                      } else {
                        return (<div key={index} className='mr-1'>{addOn},</div>)
                      }
                    })
                  }
                </Typography>
              </CardContent>
            </CardActionArea>
          </Grid>
        </Grid>
      </div>
    </Card>
  )
}