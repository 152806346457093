import {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {BASE_URI} from './Constants'
import {AlertContext} from './Contexts/AlertContext'
import moment from 'moment'

export default function BidsFilter(pageNumber, pageSize, status, order, orderBy, forceReload) {
  const { addAlert } = useContext(AlertContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [bids, setBids] = useState([])
  const [total, setTotal] = useState(0)

  const changeStatusWording = (status) => {
    switch (status.toLowerCase()) {
      case 'waitlisted':
        return 'active'
      case 'successful':
        return 'concluded'
      case 'rejected':
        return 'lost'
      default:
        return status
    }
  }

  useEffect(() => {
    setBids([])
  }, [pageNumber, pageSize, status, order, orderBy, forceReload])

  useEffect(() => {
    let cancel
    setLoading(true)
    setError(false)
    const params = {
      pageNumber: pageNumber + 1,
      pageSize
    }
    if (status) { params.status = status }
    if (order) {params.sortOrder = order.toUpperCase()}
    if (orderBy) {params.sortColumn = orderBy}
    axios({
      method: 'GET',
      url: `${BASE_URI}/bid`,
      params,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {
      if (response.data?.bids) {
        const transformedBids = response.data.bids.map((obj) => {
          return {
            vehicleListingId: obj.vehicleListingId,
            vehicleName: obj.vehicleName,
            bidDate: moment(obj.dateOfBid).format('D MMM, YYYY'),
            bidAmount: obj.amount,
            highestBid: obj.highestBid,
            bidStatus: changeStatusWording(obj.status),
            bidId: obj.id,
            vehicleImage: obj.vehicleImage
          }
        })
        setBids(transformedBids)
      }
      setTotal(response.data?.totalCount)
      setLoading(false)
    }).catch( error => {
      if(axios.isCancel(error)) return
      if (error.response) {
        addAlert('Cannot perform query')
        setError(true)
      }
      setLoading(false)
    })
    return () => cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize, status, order, orderBy, forceReload])

  return {loading, error, bids, total}
}