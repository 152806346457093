import React, {useState, useRef, useCallback} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReviewCard from '../shared/ReviewCard';
import ReviewFilter from '../shared/ReviewFilter';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ReviewsContainer({history}) {
  const [pageNumber, setPageNumber] = useState(1)
  const{
    loading,
    reviews,
    hasMore
  }= ReviewFilter(pageNumber)

  const observer = useRef()
  const lastReviewsRef = useCallback(node => {
    if (loading) return
    if (observer.current) {
      observer.current.disconnect()
    }
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) {
      observer.current.observe(node)
    }
  }, [loading, hasMore])


  const navigateToVehicle = (id) => {
    const newLocation = {
      pathname: `/vehicle/${id}`,
      state: { from: 'Reviews' }
    }
    history.push(newLocation)
  }

  return (
    <Grid container direction="column">
      <Grid container spacing={2} justify='space-between' className="mb-3">
        <Grid item>
          <Typography variant="h5" className="font-weight-bold" gutterBottom>
            Reviews
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {
          reviews.map((review, index) => {
            if (reviews.length === index + 1) {
              return <Grid item xs={12} sm={4} key={review.id} ref={lastReviewsRef}>
                        <ReviewCard review={review} openVehicle={navigateToVehicle} />
                      </Grid>
            }
            return <Grid item xs={12} sm={4} key={review.id}>
                      <ReviewCard review={review} openVehicle={navigateToVehicle} />
                    </Grid>

          })
        }
        {
          (reviews.length === 0 && !loading) &&
            <Grid container className="pl-2">
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" color="textSecondary">
                  No reviews
                </Typography>
              </Grid>
            </Grid>
        }
        { loading &&
            <Grid container className="mt-5" alignItems="center" justify="center">
              <Grid item>
                <CircularProgress size={80} />
              </Grid>
            </Grid>
        }
      </Grid>
    </Grid>
  );
}