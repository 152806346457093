import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { StyledDialogTitle} from '../../shared/StyledDialogs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {
  NEW_LISTING_PAYMENT_AMOUNT
} from '../../shared/Constants'
import PaymentComponent from '../../shared/PaymentComponent';
import {useHistory} from 'react-router-dom'


export default function NewListingDialog({action, open, closeDialog, forceRedirect, listingCount}) {
  let history = useHistory();

  const checkRedirect= () => {
    if (forceRedirect) {
      history.push(`/myVehicles`)
    }
  }

  return (
    <Dialog maxWidth='xs'
            onExit={checkRedirect}
            onClose={closeDialog}
            open={open}>
      <StyledDialogTitle onClose={closeDialog}>
        Create New Listing
      </StyledDialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          {
            listingCount <= 0  ?
              `You already have made use of the free listings available
              on Avo. Do you wish to pay a fee of R${NEW_LISTING_PAYMENT_AMOUNT} to create more listings?`
              :
              `Do you wish to create a new listing?`
          }
        </Typography>
      </DialogContent>
      <DialogActions className="py-3 px-3">
        <Button color="secondary" onClick={closeDialog}>Cancel</Button>
          {
            listingCount <=0 && <PaymentComponent />
          }
          {
            listingCount > 0 &&
            <Button variant="contained" onClick={()=> action('new')} color="primary">
              New Listing
            </Button>
          }
      </DialogActions>
    </Dialog>
  )

}
