import React, {useEffect, useContext, useState, useRef} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {useHistory, useParams} from 'react-router-dom'
import EditListingDialog from './EditListingDialog'
import NewListingDialog from './NewListingDialog'
import {StyledTab, StyledTabs} from '../../shared/StyledTabs'
import VehicleView from '../VehicleView'
import VehicleBidContainer from '../VehicleBidContainer'
import {ACTIVE_STATE, ADMIN_ROLE, BASE_URI} from '../../shared/Constants'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AlertContext} from '../../shared/Contexts/AlertContext'
import GlobalStyles from '../../GlobalStyles'
import clsx from 'clsx'
import {AuthContext} from '../../shared/Contexts/AuthContext'
import DeleteListingDialog from '../DeleteListingDialog'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: '#036efd',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

export default function SellerVehicleActions({ vehicleState, vehicleDetails, bidCount}) {
  let history = useHistory();
  const [openNewDialog, setNewDialog] = useState(false)
  const [openEditDialog, setEditDialog] = useState(false)
  const [openDeleteDialog, setDeleteDialog] = useState(false)
  const [tabIndex, setTabIndex] = useState(0);
  const { id } = useParams();
  const [highestBid, setHighestBid] = useState(null)
  const mountedRef = useRef(true)
  const { addAlert } = useContext(AlertContext)
  const { role } = useContext(AuthContext);
  const [listingCount, setListingCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const classes = useStyles();
  const globalStyles = GlobalStyles();

  const handleTabChange = (event,newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true)
    const getPersonalDetails = async () => {
      try {
        const result = await fetchPersonalDetails(source)
        setListingCount(result.data?.numAvailableListings)
      } catch (error) {
        if (error.response) {
          addAlert('Error fetching personal details')
        }
      }
    }
    if (role !== ADMIN_ROLE) {
      getPersonalDetails().then(() => {
        setLoading(false)
        if (!mountedRef.current) return null
      })
    }
    return () => {
      mountedRef.current = false
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchPersonalDetails = (source) => {
    return axios.get(`${BASE_URI}/user`, {
      cancelToken : source.token
    })
  }

  const handleDialogAction = (action) => {
    if(action ==='new') {
      setNewDialog(false)
      history.push(`/sell-car`)
    } else if (action === 'pay') {
      setNewDialog(false)
    } else {
      setEditDialog(false)
      history.push(`/sell-car/${id}`)
    }
  }

  const deleteVehicle = () =>{
    processDeleteVehicle().then(() => {
      history.push(`/myVehicles`)
      addAlert("Deleted vehicle")
    }).catch(() => {
      addAlert("Unable to delete vehicle")
    })
  }

  const processDeleteVehicle = () => {
    return axios.delete(`${BASE_URI}/vehicle`, {
      params: {
        vehicleListingId: id
      }
    })
  }


  useEffect(() => {
    if (vehicleDetails && vehicleDetails.highestBid > 0) {
      setHighestBid({bidId: vehicleDetails.bidId, bidAmount: vehicleDetails.highestBid})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container direction="column">
      <EditListingDialog bidCount={bidCount} action={handleDialogAction}
                         open={openEditDialog}
                         closeDialog={()=>setEditDialog(false)} />
      <DeleteListingDialog open={openDeleteDialog}
                           name={vehicleDetails.name}
                           deleteListing={deleteVehicle}
                           closeDialog={()=>setDeleteDialog(false)} />
      <NewListingDialog open={openNewDialog}
      listingCount={listingCount}
      action={handleDialogAction}
      closeDialog={()=> setNewDialog(false)}/>

      <Grid container spacing={2} justify='space-between' className="mb-3">
        <Grid item>
          <Typography variant="h4" className="font-weight-bold pl-2" gutterBottom>
            {role === ADMIN_ROLE ? 'User Listing': 'My Listing'}
          </Typography>
        </Grid>
        <Grid item className="d-flex">
          <Button onClick={()=> setEditDialog(true)} className="m-2"
                  variant="contained">
            Edit
          </Button>
          {
            role === ADMIN_ROLE &&
              <Button onClick={()=> setDeleteDialog(true)}
                      variant="contained" color="primary"
                      className={clsx('m-2', globalStyles.buttonRed)}>
                Delete
              </Button>
          }
          {
            role !== ADMIN_ROLE &&
            <div className={classes.wrapper}>
              <Button onClick={()=> setNewDialog(true)}
                      className="m-2"
                      disabled={loading}
                      variant="contained" color="primary">
                New Listing
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          }
        </Grid>
      </Grid>
      <StyledTabs value={tabIndex} onChange={handleTabChange}>
        <StyledTab label="Overview"/>
        { (vehicleState === ACTIVE_STATE && bidCount > 0) &&
          <StyledTab label="Bids" bidcount={bidCount} />
        }
      </StyledTabs>
      {tabIndex === 0 && (<VehicleView vehicleDetailsProp={vehicleDetails} vehicleState={vehicleState}/>)}
      {tabIndex === 1 && (<VehicleBidContainer highestBid={highestBid}/>)}
    </Grid>
  );
}
