import React from "react";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export default function PersonalDetails({details}) {

  return (
    <React.Fragment>
      {
        !Object.keys(details).length &&
        <Grid container className="p-5">
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="textSecondary">
              No personal information available
            </Typography>
          </Grid>
        </Grid>
      }
      {
        Object.keys(details).length > 0 &&
        <Grid container className="p-5">
          <Grid item xs={12} sm={4}>
            <div className="pb-5">
              <Typography variant="caption" color="textSecondary" component="p">
                Full Name
              </Typography>
              <Typography variant="body2" component="p">
                <strong>{details.firstName} {details.lastName}</strong>
              </Typography>
            </div>
            <div className="pb-5">
              <Typography variant="caption" color="textSecondary" component="p">
                Mobile Number
              </Typography>
              <Typography variant="body2" component="p">
                <strong>{details.mobileNumber}</strong>
              </Typography>
            </div>
            <div>
              <Typography variant="caption" color="textSecondary" component="p">
                Email Address
              </Typography>
              <Typography variant="body2" component="p">
                <strong>{details.emailAddress}</strong>
              </Typography>
            </div>
          </Grid>
        </Grid>
      }
    </React.Fragment>
  );
}