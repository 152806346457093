import React, {useState, useContext}  from "react";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import Paper from '@material-ui/core/Paper';
import { DatePicker } from "@material-ui/pickers";
import axios from 'axios/index'
import {AlertContext} from '../shared/Contexts/AlertContext'
import {LoadingContext} from '../shared/Contexts/LoadingContext'
import {BASE_URI} from '../shared/Constants'
import {format, setHours, setMinutes, startOfMonth, endOfMonth} from 'date-fns';


export default function AdminReports() {
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));
  const { addAlert } = useContext(AlertContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)

  const fetchSellerReport = (source) => {
    return axios.get(`${BASE_URI}/dashboard/paymentReport`, {
      params: {
        start: convertDate(startDate),
        end: convertDate(endDate)
      },
      cancelToken : source.token,
      responseType: 'blob'
    })
  }

  const fetchUserLoginsReport = (source) => {
    return axios.get(`${BASE_URI}/dashboard/userLoginReport`, {
      params: {
        start: convertDate(startDate),
        end: convertDate(endDate)
      },
      cancelToken : source.token,
      responseType: 'blob'
    })
  }

  const fetchVehicleSellerReport = (source) => {
    return axios.get(`${BASE_URI}/dashboard/vehicleSellerReport`, {
      cancelToken : source.token,
      responseType: 'blob'
    })
  }

  const convertDate = (date) => {
    if (date) {
      let resetHours = setHours(date, 0)
      return setMinutes(resetHours, 0)
    }
    return null
  }

  const fetchStatisticsReport = (source) => {
    return axios.get(`${BASE_URI}/dashboard/statistics`, {
      params: {
        start:convertDate(startDate),
        end: convertDate(endDate)
      },
      cancelToken : source.token,
      responseType: 'blob'
    })
  }

  const fetchBillingReport = (source) => {
    return axios.get(`${BASE_URI}/dashboard/dealerPurchaseReport`, {
      params: {
        start:convertDate(startDate),
        end: convertDate(endDate)
      },
      cancelToken : source.token,
      responseType: 'blob'
    })
  }

  const getBillingReport =() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    showLoading()
    fetchBillingReport(source).then((res) => {
      hideLoading()
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', constructFileName('BillingReport'));
      document.body.appendChild(link);
      link.click();
      addAlert('Billing report downloaded')
    }).catch((err) => {
      hideLoading()
      addAlert('Unable to download Billing Report, please try again later')
    })
  }

  const getSellerReport =() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    showLoading()
    fetchSellerReport(source).then((res) => {
      hideLoading()
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', constructFileName('SellerReport'));
      document.body.appendChild(link);
      link.click();
      addAlert('Seller report downloaded')
    }).catch((err) => {
      hideLoading()
      addAlert('Unable to download Seller Report, please try again later')
    })
  }

  const getVehicleSellerReport =() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    showLoading()
    fetchVehicleSellerReport(source).then((res) => {
      hideLoading()
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', constructFileName('VehicleReport'));
      document.body.appendChild(link);
      link.click();
      addAlert('Vehicle report downloaded')
    }).catch((err) => {
      hideLoading()
      addAlert('Unable to download Vehicle Report, please try again later')
    })
  }

  const getUserLoginsReport =() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    showLoading()
    fetchUserLoginsReport(source).then((res) => {
      hideLoading()
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', constructFileName('UserLoginReport'));
      document.body.appendChild(link);
      link.click();
      addAlert('User Login report downloaded')
    }).catch((err) => {
      hideLoading()
      addAlert('Unable to download User Login Report, please try again later')
    })
  }

  const constructFileName = (fileType) => {
    let dateRange = null
    if (startDate && endDate) {
      dateRange = `${format(startDate, 'dd/MM/yyyy')}-${format(endDate, 'dd/MM/yyyy')} `
    } else {
      dateRange = startDate ? format(startDate, 'dd/MM/yyyy'):endDate ? format(endDate, 'dd/MM/yyyy'): 'All'
    }
    return `WePay4Cars_${fileType}_${dateRange}.csv`
  }

  const getStatisitcs =() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    showLoading()
    fetchStatisticsReport(source).then((res) => {
      hideLoading()
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', constructFileName('PlatformUsage'));
      document.body.appendChild(link);
      link.click();
      addAlert('Platform usage report downloaded')
    }).catch((err) => {
      hideLoading()
      addAlert('Unable to download Platform Usage Report, please try again later')
    })
  }

  return (
    <Grid container direction="column">
      <Grid container spacing={2} justify='space-between'>
        <Grid item>
          <Typography variant="h4" className="font-weight-bold" gutterBottom>
            Reports
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Please select a date range for any of the listed reports.
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mb-5" alignItems="center">
        <Grid item xs={12} md={3}>
          <DatePicker
            autoOk
            format="dd/MM/yyyy"
            label="Start Date"
            value={startDate}
            maxDate={endDate}
            onChange={setStartDate}
            animateYearScrolling
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <DatePicker
            autoOk
            format="dd/MM/yyyy"
            label="End Date"
            minDate={startDate}
            value={endDate}
            onChange={setEndDate}
            animateYearScrolling
          />
        </Grid>

      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3}>
          <List>
            <ListItem divider>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Platform Usage"
              />
              <ListItemSecondaryAction>
                <IconButton edge="end"
                            onClick={getStatisitcs}
                            aria-label="download statistics report">
                  <GetAppIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem divider>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Billing Report"
              />
              <ListItemSecondaryAction>
                <IconButton edge="end"
                            onClick={getBillingReport}
                            aria-label="download billing report">
                  <GetAppIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem divider>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Seller Report"
              />
              <ListItemSecondaryAction>
                <IconButton edge="end"
                            onClick={getSellerReport}
                            aria-label="download seller report">
                  <GetAppIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem divider>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="User Logins Report"
              />
              <ListItemSecondaryAction>
                <IconButton edge="end"
                            onClick={getUserLoginsReport}
                            aria-label="download user logins report">
                  <GetAppIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Vehicle Report"
              />
              <ListItemSecondaryAction>
                <IconButton edge="end"
                            onClick={getVehicleSellerReport}
                            aria-label="download vehicle report">
                  <GetAppIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

          </List>
        </Paper>
      </Grid>
    </Grid>
  )
}