import React, {useContext} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {StyledDialogTitle} from '../shared/StyledDialogs'
import PersonalDetailsForm from './Seller/PersonalDetailsForm'
import {AlertContext} from '../shared/Contexts/AlertContext'


export default function UserDialog({open, closeDialog, user}) {
  const { addAlert } = useContext(AlertContext)

  const submittedAction =(action) => {
    addAlert(`Successfully ${action} user`)
    closeDialog()
  }

  return (
    <Dialog fullWidth onClose={closeDialog}  open={open}>
      <StyledDialogTitle onClose={closeDialog}>
        Add {user.role === 'Dealer' ? 'Dealer': 'User'}
      </StyledDialogTitle>
      <DialogContent>
        <PersonalDetailsForm user={user}
                             closeDialog={closeDialog}
                             modalView={true} onSubmit={submittedAction} />
      </DialogContent>
    </Dialog>
  )

}
