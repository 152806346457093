import {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {BASE_URI, PAGE_SIZE} from './Constants'
import {AlertContext} from './Contexts/AlertContext'

export default function CarFilter(filterIds, pageNumber, sortDirection) {
  const { addAlert } = useContext(AlertContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [vehicles, setVehicles] = useState([])
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    setVehicles([])
  }, [filterIds, sortDirection])

  useEffect(() => {
    let cancel
    setLoading(true)
    setError(false)
    const params = {
      pageNumber,
      pageSize: PAGE_SIZE,
      sortOrder: sortDirection
    }
    if (filterIds.make) { params.make = filterIds.make }
    if (filterIds.model) { params.model = filterIds.model }
    if (filterIds.location) { params.location = filterIds.location }
    if (filterIds.emailAddress) { params.userEmailAddress = filterIds.emailAddress }
    if (filterIds.vehicleId) { params.vehicleListingId = filterIds.vehicleId }
    axios({
      method: 'GET',
      url: `${BASE_URI}/vehicle/`,
      params,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {
      setVehicles(prevVehicles => {
        let combinedArray = [...new Set([...prevVehicles, ...response.data?.vehicles])]
        const removedDuplicates= combinedArray.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
        return removedDuplicates
      })
      setHasMore(response.data?.vehicles.length > 0)
      setLoading(false)
    }).catch( error => {
      if(axios.isCancel(error)) return
      if (error.response?.data) {
        addAlert(error.response.data.error)
      } else if (error.response) {
        addAlert('Cannot perform query')
        setError(true)
      }
      setLoading(false)
    })
    return () => cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterIds, pageNumber,sortDirection])

  return {loading, error, vehicles, hasMore}
}