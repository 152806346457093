import {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {BASE_URI} from './Constants'
import {AlertContext} from './Contexts/AlertContext'

export default function UserFilter(filter, pageNumber, pageSize) {
  const { addAlert } = useContext(AlertContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [users, setUsers] = useState([])
  const [total, setTotal] = useState(0)


  useEffect(() => {
    setUsers([])
  }, [pageNumber, pageSize, filter])

  useEffect(() => {
    let cancel
    setLoading(true)
    setError(false)
    const params = {
      pageNumber: pageNumber + 1,
      pageSize
    }
    if (filter) { params.searchTerm = filter }
    axios({
      method: 'GET',
      url: `${BASE_URI}/user`,
      params,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {
      if (response.data?.users) {
        setUsers(response.data.users)
      }
      setTotal(response.data?.totalCount)
      setLoading(false)
    }).catch( error => {
      if(axios.isCancel(error)) return
      if (error.response) {
        addAlert('Cannot Get all Users')
        setError(true)
      }
      setLoading(false)
    })

    return () => cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize, filter])

  return {loading, error, users, total}
}