import React, { useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import { StyledDialogTitle} from '../../shared/StyledDialogs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import GlobalStyles from '../../GlobalStyles';
import Rating from '@material-ui/lab/Rating';
import Avatar from '@material-ui/core/Avatar';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import clsx from 'clsx';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import { makeStyles } from '@material-ui/core/styles';
import {CurrencyValue} from '../../shared/TextMask'

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  }
}));

export default function BidDialog({bidderProp, amount , statusBid, open, processBid, closeDialog}) {
  const classes = GlobalStyles();
  const localStyles = useStyles();
  const [rating, setRating] = useState(0)
  const [bidder, setBidder] = useState({
    img: '',
    name: ''
  })

  const handleClose = () => {
    closeDialog()
  }

  useEffect(() => {
    if (bidderProp) {
      setBidder(bidderProp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog maxWidth='xs' onClose={handleClose}  open={open}>
      <StyledDialogTitle onClose={handleClose}>
        { statusBid === 'Accept' ? 'Accept Bid' : 'Dismiss Bid'}
      </StyledDialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          {
            statusBid === 'Accept' ?
              `The dealer will contact you shortly, and will most likely wish to view the vehicle in person.`
              :
              <React.Fragment>
                Please rate your experience with the dealer, the bid of {<CurrencyValue value={amount}/>} will
                be rejected and the bids made by other dealers will become active.
              </React.Fragment>
          }
        </Typography>
        {
          statusBid !== 'Accept' &&  <Grid className="mt-3" container alignItems='center' spacing={2}>
                                                {
                                                  bidder.img &&
                                                  <Grid item>
                                                    <Avatar src={bidder.img} className={localStyles.large}></Avatar>
                                                  </Grid>
                                                }
                                              <Grid item>
                                                {
                                                  bidder.name && <Typography variant="h6">{bidder.name}</Typography>
                                                }
                                                {
                                                  !bidder.name && <Typography variant="body2" color="textSecondary">Please rate the dealer</Typography>
                                                }
                                                <Rating
                                                  name="rateDealer" size="large"
                                                  value={rating}
                                                  precision={0.5}
                                                  onChange={(event, newValue) => {
                                                    setRating(newValue);
                                                  }}
                                                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                                />
                                              </Grid>
                                            </Grid>
        }
      </DialogContent>
      <DialogActions className="py-3 px-3">
        <Button color="secondary" onClick={handleClose}>Cancel</Button>
        <Button className={clsx({
          [classes.buttonGreen]: statusBid === 'Accept',
          [classes.buttonRed]: statusBid === 'Reject' && !(statusBid === 'Reject' && rating <= 0)})}
                variant="contained"
                onClick={() => processBid(rating)}
                disabled={statusBid === 'Reject' && rating <= 0}
                style={{padding: '0.6rem 1.5rem '}}
                color="primary">
          {statusBid === 'Accept' ? 'Accept':'Dismiss Deal'}
        </Button>
      </DialogActions>
    </Dialog>
  )

}
