import React, { useState, useEffect, useContext } from "react";
import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import GlobalStyles from '../GlobalStyles'
import BidDialog from './Seller/BidDialog'
import clsx from 'clsx'
import ConcludeDialog from './Seller/ConcludeDialog'
import {useParams} from "react-router-dom";
import ContactInfo from './ContactInfo'
import {CurrencyConverter, CurrencyInput, CurrencyValue} from '../shared/TextMask'
import VehicleDetails from './VehicleDetails'
import {ACTIVE_STATE, CONCLUDING_STATE, DEALER_ROLE, ADMIN_ROLE, DRAFT_STATE, SELLER_ROLE, SOLD_STATE} from '../shared/Constants'
import {AuthContext} from '../shared/Contexts/AuthContext'
import { makeStyles } from '@material-ui/core/styles';
import ReviewHeader from './Dealer/ReviewHeader'
import TextField from '@material-ui/core/TextField'
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment'
import {BidContext} from '../shared/Contexts/BidContext'
import {LoadingContext} from '../shared/Contexts/LoadingContext'
import {AlertContext} from '../shared/Contexts/AlertContext'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(() => ({
  dealerSoldHeader: {
    color: '#FFF',
    backgroundColor: '#498d20'
  },
  acceptBidBtn: {
    borderRadius: '5px',
    color: 'white',
    height: '30px',
    width: '30px',
    padding: 0,
    marginLeft: '15px'
  },
  active: {
    background: 'linear-gradient(90deg, rgba(111,208,32,1) 0%, rgba(77,148,25,1) 50%)'
  },
  disabled: {
    pointer: 'none',
    background: 'rgba(0, 0, 0, 0.18) !important',
    color: 'white !important'
  }
}));


export default function VehicleView({vehicleState, vehicleDetailsProp}) {
  const classes = GlobalStyles();
  const localStyles = useStyles();
  const { id } = useParams();
  const { role } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState({});
  const [openConclude, setOpenConclude] = useState(false);
  const [bidStatus, setBidStatus] = useState('');
  const [state, setState] = useState('');
  const [errorDealerBid, setErrorDealerBid] = useState(false)
  const [editBid, setEditBid] = useState(true)
  const { acceptBid, rejectBid, concludeDeal, postBid, putBid} = useContext(BidContext)
  const { addAlert } = useContext(AlertContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    setState(vehicleState)
  }, [vehicleState])

  useEffect(() => {
    if (vehicleDetailsProp && vehicleState) {
      let tmpBidDetails = {}
      setState(vehicleState)
      if (vehicleDetailsProp.bidDetails) {
        tmpBidDetails = vehicleDetailsProp.bidDetails
        if (vehicleDetailsProp.bidDetails.amount && vehicleDetailsProp.bidDetails.amount > 0) {
          setEditBid(false)
        }
      } else {
        tmpBidDetails = {
          bidId: null,
          dateOfBid: moment( new Date()).format('D MMMM YYYY'),
          amount: null,
          status: null
        }
      }
      setVehicleInfo({...vehicleDetailsProp, bidDetails: tmpBidDetails})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleAcceptBidModalOpen = () => {
    setBidStatus('Accept');
    setOpen(true);
  };
  const handleBidModalClose = () => {
    setOpen(false);
  };
  const handleRejectBidModalOpen = () => {
    setBidStatus('Reject');
    setOpen(true);
  };
  const handleBidRequest = (dealerRating) => {
    if (bidStatus === 'Accept') {
      acceptBid(vehicleInfo.bidId)
    } else if (bidStatus === 'Reject') {
      rejectBid(vehicleInfo.bidDetails.bidId, dealerRating)
    }
    handleBidModalClose()
  };
  const openConcludeModal = () => {
    setOpenConclude(true)
  };
  const closeConcludeModal = () => {
    setOpenConclude(false);
  };

  const concludeDealRequest = (finalPrice, rating, selectedTags) => {
    concludeDeal(vehicleInfo.bidId, finalPrice, rating, selectedTags)
    closeConcludeModal()
  }

  const handleDealerBid = (event) => {
    let dealerBid = event.target.value
    if(dealerBid === 'R0' || dealerBid <=0) {
      setErrorDealerBid(true)
    } else {
      setErrorDealerBid(false)
    }
    let tmpVehicle = {...vehicleInfo}
    tmpVehicle.bidDetails.amount= CurrencyConverter(dealerBid)
    setVehicleInfo(tmpVehicle)
  }

  const toggleBidEdit = () => {
    setEditBid(prevState => !prevState)
  }

  const setBidDetails = (bidDetails) => {
    const tmpBidDetails = {
      bidId: bidDetails.id,
      dateOfBid: moment( new Date()).format('D MMMM YYYY'),
      amount: bidDetails.amount,
      status: bidDetails.status
    }
    if (bidDetails.amount > vehicleInfo.highestBid || tmpBidDetails.bidId === vehicleInfo.bidId) {
      let tmpVehicle = {...vehicleInfo}
      tmpVehicle.bidId = bidDetails.id
      tmpVehicle.highestBid = bidDetails.amount
      tmpVehicle.bidDetails = tmpBidDetails
      setVehicleInfo(tmpVehicle)
    } else {
      setVehicleInfo({...vehicleInfo, bidDetails: tmpBidDetails})
    }
  }

  const submitBid = () => {
    if (vehicleInfo.bidDetails && vehicleInfo.bidDetails.amount > 0 && id) {
      showLoading()
      if (vehicleInfo.bidDetails.bidId) {
        putBid(vehicleInfo.bidDetails.bidId, vehicleInfo.bidDetails.amount).then((response) => {
          toggleBidEdit()
          hideLoading()
          setBidDetails(response.data)
          addAlert(`Successfully edited bid to R${vehicleInfo.bidDetails.amount}`)
        }).catch((error) => {
          hideLoading()
          addAlert(`Unable to edit bid of R${vehicleInfo.bidDetails.amount}`)
        })
      } else {
        postBid(id, vehicleInfo.bidDetails.amount).then((response) => {
          toggleBidEdit()
          hideLoading()
          setBidDetails(response.data)
          addAlert(`Successfully Posted bid of R${vehicleInfo.bidDetails.amount}`)
        }).catch((error) => {
          hideLoading()
          addAlert(`Unable to post bid of R${vehicleInfo.bidDetails.amount}`)
        })
      }
    } else {
      addAlert(`Invalid bid`)
    }
  }

  const cardActions = (
    <Grid container className={clsx('mr-sm-3 mr-0 px-md-0 px-3', {[localStyles.dealerSoldHeader]: role === DEALER_ROLE && state === SOLD_STATE})}>
      {
        role === DEALER_ROLE &&
        <Grid item className='d-flex flex-column align-items-md-end'>
          {
            state === ACTIVE_STATE &&
            <React.Fragment>
              {
                vehicleInfo.highestBid > 0 &&
                <Typography variant="h5">
                  Highest Bid: <strong><CurrencyValue value={vehicleInfo.highestBid} /></strong>
                </Typography>
              }
              {
                !editBid &&
                <React.Fragment>
                  <Grid item className='d-flex'>
                    <Typography variant="h5" className="pr-3">
                      Current Bid: <strong><CurrencyValue value={vehicleInfo.bidDetails.amount ? vehicleInfo.bidDetails.amount: 0} /></strong>
                    </Typography>
                    <IconButton aria-label="edit bid"
                                className="p-0"
                                onClick={toggleBidEdit}
                                color="primary">
                      <EditIcon size="small"/>
                    </IconButton>
                  </Grid>
                </React.Fragment>
              }
              {
                editBid &&
                <Grid item className='d-flex mt-2 align-items-center'>
                  <TextField
                    fullWidth
                    required
                    id="dealerBid"
                    label="Your bid"
                    value={vehicleInfo.bidDetails.amount ? vehicleInfo.bidDetails.amount: null}
                    onChange={handleDealerBid}
                    placeholder={'eg. 200 000'}
                    error={errorDealerBid}
                    helperText={errorDealerBid ? 'Please enter a valid amount' : ' '}
                    InputProps={{
                      inputComponent: CurrencyInput
                    }}
                  />
                  <IconButton aria-label="confirm bid"
                              disabled={(errorDealerBid || vehicleInfo.bidDetails.amount === 0)}
                              className={clsx(localStyles.acceptBidBtn,{[localStyles.disabled]: (errorDealerBid || vehicleInfo.bidDetails.amount === 0)}, {[localStyles.active]: !errorDealerBid})}
                              onClick={submitBid}>
                    <CheckIcon size="small"/>
                  </IconButton>
                </Grid>
              }
            </React.Fragment>
          }
          {
            state === CONCLUDING_STATE &&
            <Grid item container>
              <Grid item>
                <Typography variant="h5" align='right'>
                  Seller accepted bid for: <strong><CurrencyValue value={vehicleInfo.bidDetails.amount ? vehicleInfo.bidDetails.amount: 0} /></strong>
                </Typography>
                <Typography variant="body2" align='right' color="textSecondary">
                  Invoicing will be done by Bid4Cars
                </Typography>
              </Grid>
              <Grid item>
                <Button onClick={openConcludeModal} className="ml-3 mt-2" variant="contained" color="secondary">
                  Deal Successful
                </Button>
              </Grid>

            </Grid>
          }
          {
            state === SOLD_STATE &&
            <div className="my-3 my-md-0">
              <Typography variant="h5" gutterBottom color="inherit">
                Sold for: <strong><CurrencyValue value={vehicleInfo?.finalPrice? vehicleInfo?.finalPrice: 0} /></strong>
              </Typography>
              <Typography variant="h6" color="inherit">
                Deal Concluded
              </Typography>
            </div>
          }
        </Grid>
      }
      {
        role === SELLER_ROLE &&
        <React.Fragment>
          <Grid item className="pr-4 d-flex align-items-center">
            {
              (vehicleInfo.highestBid > 0 || (vehicleInfo.bidDetails && vehicleInfo.bidDetails.amount > 0)) &&
              <Typography variant="h5" className="mb-3 mb-md-0">
                {state === CONCLUDING_STATE ? 'Accepted Bid: ' : state === SOLD_STATE ? 'Sold for: ': 'Highest Bid: '}
                {
                  ((vehicleInfo.bidDetails &&vehicleInfo.bidDetails.amount) > 0 && state === CONCLUDING_STATE) &&
                  <strong><CurrencyValue value={vehicleInfo.bidDetails.amount ? vehicleInfo.bidDetails.amount: 0} /></strong>
                }
                {
                  state === SOLD_STATE &&
                  <strong><CurrencyValue value={vehicleInfo?.finalPrice? vehicleInfo?.finalPrice: 0} /></strong>
                }
                {
                  (vehicleInfo.highestBid > 0 && state === ACTIVE_STATE) &&
                  <strong><CurrencyValue value={vehicleInfo.highestBid} /></strong>
                }
              </Typography>
            }
          </Grid>
          <Grid item className='mb-3 mb-md-0'>
            {
              (state === ACTIVE_STATE && vehicleInfo.highestBid > 0) &&
              <Button onClick={handleAcceptBidModalOpen} variant="contained" color="secondary">
                Accept Bid
              </Button>

            }
            {
              state === CONCLUDING_STATE &&
              <div>
                <Button className={clsx('mr-2', classes.buttonRed)} onClick={handleRejectBidModalOpen} variant="contained" color="primary">
                  Deal not done
                </Button>
                <Button onClick={openConcludeModal} className="ml-2" variant="contained" color="secondary">
                  Deal Successful
                </Button>
              </div>
            }
          </Grid>
        </React.Fragment>
      }
    </Grid>
  )

  return (
    <Card className="mt-4">
      <CardHeader className={clsx('pt-4', {[localStyles.dealerSoldHeader]: role === DEALER_ROLE && state === SOLD_STATE})}
                  title={
                    <React.Fragment>
                      {
                        (state === ACTIVE_STATE || state === DRAFT_STATE) && vehicleInfo.name
                      }
                      {
                        (state === CONCLUDING_STATE || state === SOLD_STATE) && role !== ADMIN_ROLE && <ContactInfo user={vehicleInfo.contactDetails} />
                      }
                    </React.Fragment>
                  }
                  action={
                    matches ? cardActions : <React.Fragment></React.Fragment>
                  }
      />
      {
        !matches ? cardActions: <React.Fragment></React.Fragment>
      }
      {
        (role === DEALER_ROLE && state === SOLD_STATE) && <ReviewHeader id={id} />
      }
      <ConcludeDialog bidderProp={vehicleInfo.contactDetails}
                      amount={ vehicleInfo.bidDetails ? vehicleInfo.bidDetails.amount: 0}
                      open={openConclude}
                      concludeDeal={concludeDealRequest}
                      closeDialog={closeConcludeModal}/>
      <BidDialog bidder={vehicleInfo.contactDetails}
                 amount={vehicleInfo.bidDetails ? vehicleInfo.bidDetails.amount: 0}
                 statusBid={bidStatus}
                 open={open}
                 processBid={handleBidRequest}
                 closeDialog={handleBidModalClose}/>
      <VehicleDetails vehicleDetails={vehicleInfo ? vehicleInfo: {}}
                      images={vehicleInfo.imgArr ? vehicleInfo.imgArr: {}} />
    </Card>
  );
}