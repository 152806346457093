import React, { useContext, useRef, useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
//import PersonIcon from '@material-ui/icons/Person';
import { AuthContext } from '../shared/Contexts/AuthContext';
import { ADMIN_ROLE, BASE_URI, DEALER_ROLE, POLLING_DELAY, SELLER_ROLE } from '../shared/Constants'
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import logo from './HomePage/logo.png';

import NotificationsIcon from '@material-ui/icons/Notifications';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Badge from '@material-ui/core/Badge'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import TimeAgo from 'react-timeago'
import useInterval from '../shared/useInterval'
import { AlertContext } from '../shared/Contexts/AlertContext'
import { useHistory } from 'react-router-dom'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HelpDialog from '../shared/HelpDialog'


const drawerWidth = 240;

const StyledBadge = withStyles((theme) => ({
    badge: {
        top: 14,
        right: 15,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        cursor: 'pointer'
    },
}))(Badge);

const navBarStyles = makeStyles((theme) => ({
    link: {
        padding: '1rem !important',
        cursor: 'pointer',
        minHeight: '64px',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#5c92a7',
            color: '#FFF !important',
            textDecoration: 'none'
        }
    },
    active: {
        backgroundColor: '#5c92a7',
        color: '#FFF',
        textDecoration: 'none'
    },
    background: {
        // background: 'transparent !important'
        background: 'rgba(242,242,244,1)'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    logo: {
        height: 'auto',
        width: '100%',
        maxWidth: '250px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '200px',
        },
    },
    closeMenuButton: {
        marginRight: 'auto',
        marginLeft: 0,
    },
    badgeButton: {
        '&:hover': {
            background: 'transparent',
            color: '#5c92a7'
        },
        '&:focus': {
            outline: 'none'
        },
        '&:active': {
            color: '#5c92a7'
        }
    }
}));

export default function NavbarComponent() {
    const { isAuthenticated, logout, role } = useContext(AuthContext);
    const classes = navBarStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [openHelp, setOpenHelp] = useState(false)
    const anchorRef = useRef(null);
    let history = useHistory();
    const [notifications, setNotifications] = useState([])
    const { addAlert } = useContext(AlertContext)

    const fetchNotifications = (source) => {
        return axios.get(`${BASE_URI}/dashboard/alert`, {
            params: {
                pageNumber: 1,
                pageSize: 100
            },
            cancelToken: source.token
        })
    }

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const getNotifications = async () => {
            try {
                const result = await fetchNotifications(source)
                setNotifications(result.data.reverse())
            } catch (error) {
                addAlert('Unable to retrieve notifications')
            }
        }
        if (isAuthenticated && role !== ADMIN_ROLE) {
            getNotifications()
        }
        return () => {
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    useInterval(async () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if (isAuthenticated && role !== ADMIN_ROLE) {
            const result = await fetchNotifications(source)
            setNotifications(result.data.reverse())
        }
    }, POLLING_DELAY)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);


    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    }

    const sellerLinks = (
        <React.Fragment>
            <Link variant="button" color="textPrimary"
                component={RouterLink} to='/sell-car'
                onClick={() => setMobileOpen(false)}
                activeClassName={classes.active} className={classes.link}>
                {role === ADMIN_ROLE ? 'Add Car' : 'Sell Car'}
            </Link>
            <Link variant="button" color="textPrimary"
                activeClassName={classes.active}
                onClick={() => setMobileOpen(false)}
                component={RouterLink} to='/myVehicles'
                className={classes.link}>
                {role === ADMIN_ROLE ? 'All Cars' : 'My Vehicles'}
            </Link>
        </React.Fragment>
    );

    const dealerLinks = (
        <React.Fragment>
            <Link variant="button" color="textPrimary"
                onClick={() => setMobileOpen(false)}
                activeClassName={classes.active}
                component={RouterLink} to='/myVehicles' className={classes.link}>
                {role === DEALER_ROLE ? 'Buy Car' : 'My Vehicles'}
            </Link>
            <Link variant="button" color="textPrimary"
                activeClassName={classes.active}
                onClick={() => setMobileOpen(false)}
                component={RouterLink} to='/bids' className={classes.link}>
                Bids
      </Link>
            <Link variant="button" color="textPrimary"
                activeClassName={classes.active}
                onClick={() => setMobileOpen(false)}
                component={RouterLink} to='/reviews' className={classes.link}>
                Reviews
      </Link>
        </React.Fragment>
    )

    const checkAlertType = (alertType) => {
        switch (alertType.toLowerCase()) {
            case 'good':
                return (<CheckIcon />)
            case 'notice':
                return (<PriorityHighIcon />)
            default:
                return (<React.Fragment />)
        }
    }

    const markAlertRead = (alertId) => {
        return axios.post(`${BASE_URI}/dashboard/alert`, null, {
            params: {
                alertId
            }
        })
    }

    const readNotif = (id, redirectId) => {
        if (id) {
            setOpen(false)
            if (redirectId) {
                history.push(`/vehicle/${redirectId}`)
            } else {
                history.push(`/sell-car`)
            }

            markAlertRead(id).then(() => {
                setNotifications(notifications.filter((notif) => notif.id !== id))
            }).catch((error) => {
                if (error?.response.data) {
                    addAlert(error.response.data.error)
                }
            })
        }
    }

    return (
        <AppBar position="static" color="transparent" className={classes.background} elevation={2}>
            <HelpDialog open={openHelp}
                closeDialog={() => setOpenHelp(false)} />
            <Toolbar className="flex-wrap">
                {
                    isAuthenticated &&
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                }
                <Link variant="button"
                    component={RouterLink} to='/'
                    onClick={logout}
                    className="flex-grow-1">
                    <img src={logo} className={classes.logo} alt="wePay4Cars" />
                </Link>
                {
                    isAuthenticated &&
                    <React.Fragment>
                        <Hidden smUp implementation="css">
                            <Drawer
                                variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true
                                }}
                            >
                                <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                                    <CloseIcon />
                                </IconButton>
                                <div className="d-flex flex-column flex-sm-row">
                                    {
                                        role === SELLER_ROLE && sellerLinks
                                    }
                                    {
                                        role === DEALER_ROLE && dealerLinks
                                    }
                                    {
                                        role === ADMIN_ROLE &&
                                        <React.Fragment>
                                            <Link variant="button" color="textPrimary"
                                                activeClassName={classes.active}
                                                onClick={() => setMobileOpen(false)}
                                                component={RouterLink} to='/users'
                                                className={classes.link}>
                                                Users
                      </Link>
                                            <Link variant="button" color="textPrimary"
                                                activeClassName={classes.active}
                                                onClick={() => setMobileOpen(false)}
                                                component={RouterLink} to='/myVehicles'
                                                className={classes.link}>
                                                All Cars
                      </Link>
                                            <Link variant="button" color="textPrimary"
                                                activeClassName={classes.active}
                                                onClick={() => setMobileOpen(false)}
                                                component={RouterLink} to='/adminReports'
                                                className={classes.link}>
                                                Reports
                      </Link>
                                        </React.Fragment>
                                    }
                                    <Link variant="button" color="textPrimary" onClick={logout} component={RouterLink} to='/'
                                        className={classes.link}>
                                        Logout
                  </Link>
                                </div>
                            </Drawer>
                        </Hidden>

                        <nav className="d-flex align-items-center">
                            <Hidden xsDown implementation="css" className="h-100">
                                <div className="d-flex flex-column flex-sm-row">
                                    {
                                        role === SELLER_ROLE && sellerLinks
                                    }
                                    {
                                        role === DEALER_ROLE && dealerLinks
                                    }
                                    {
                                        role === ADMIN_ROLE &&
                                        <React.Fragment>
                                            <Link variant="button" color="textPrimary"
                                                activeClassName={classes.active}
                                                onClick={() => setMobileOpen(false)}
                                                component={RouterLink} to='/users'
                                                className={classes.link}>
                                                Users
                      </Link>
                                            <Link variant="button" color="textPrimary"
                                                activeClassName={classes.active}
                                                onClick={() => setMobileOpen(false)}
                                                component={RouterLink} to='/myVehicles'
                                                className={classes.link}>
                                                All Cars
                      </Link>
                                            <Link variant="button" color="textPrimary"
                                                activeClassName={classes.active}
                                                onClick={() => setMobileOpen(false)}
                                                component={RouterLink} to='/adminReports'
                                                className={classes.link}>
                                                Reports
                      </Link>
                                        </React.Fragment>
                                    }
                                    <Link variant="button" color="textPrimary" onClick={logout} component={RouterLink} to='/'
                                        className={classes.link}>
                                        Logout
                  </Link>
                                </div>
                            </Hidden>
                            {
                                (role === DEALER_ROLE || role === SELLER_ROLE) &&
                                <StyledBadge
                                    color="secondary"
                                    badgeContent={notifications.length ? notifications.length : 0}
                                    onClick={handleToggle}
                                >
                                    <IconButton
                                        disableRipple
                                        className={classes.badgeButton}
                                        disabled={notifications.length === 0}
                                        ref={anchorRef}
                                        aria-controls={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                    >
                                        <NotificationsIcon />
                                    </IconButton>
                                </StyledBadge>
                            }
                            {
                                role === DEALER_ROLE &&
                                <IconButton
                                    disableRipple
                                    className={classes.badgeButton}
                                    onClick={() => {
                                        setMobileOpen(false)
                                        setOpenHelp(true)
                                    }}
                                >
                                    <Typography>R</Typography>
                                </IconButton>
                            }
                        </nav>
                    </React.Fragment>
                }
            </Toolbar>
            <Popper open={open} anchorEl={anchorRef.current}
                style={{ zIndex: '1000', height: '200px', overflow: 'auto' }}
                role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper elevation={3}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {
                                        notifications.length > 0 && notifications.map((notif) =>
                                            <MenuItem onClick={() => readNotif(notif.id, notif.focusItemId)} key={notif.id}>
                                                <Grid container spacing={1}
                                                    style={{ color: notif.alertType === 'Good' ? 'rgba(77,148,25,1)' : '#9e9e9e' }}>
                                                    <Grid item xs={1}>
                                                        {checkAlertType(notif.alertType)}
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <Grid item container justify="space-between">
                                                            <Grid item>
                                                                <strong>{notif.subjectType}:</strong>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="caption" color="textSecondary">
                                                                    <TimeAgo date={notif.creationDate} />
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            {notif.alertDescription}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>
                                        )
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </AppBar>
    )
}