import React, { useState, createContext, useEffect } from 'react';
import {AUTH_USER, BASE_URI, TMP_USER} from '../Constants'
import axios from 'axios';
import jwt from 'jwt-decode'
import {useHistory} from 'react-router-dom'

export const AuthContext = createContext();

export const AuthContextProvider = (props) =>  {
  let history = useHistory();
  const [isAuthenticated, setAuthenticated] = useState(false)
  const [role, setRole] = useState('')

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem(AUTH_USER));
    if (session && !isAuthenticated) {
      setRole(session.role)
      if(session.token) {
        setAuthenticated(true);
      }
    } else if (!localStorage && isAuthenticated) {
      setAuthenticated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  axios.interceptors.request.use (
    async config => {
      let session = JSON.parse(localStorage.getItem(AUTH_USER))
      if (!session) {
        session = JSON.parse(localStorage.getItem(TMP_USER))
      }
      if (session && session.token) {
        let token = createJWTToken(session.token)
        config.headers.authorization = token
      }
      return config
    },
    error => {
      Promise.reject(error)
    })

  axios.interceptors.response.use((response) => {
    return response
  }, function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && originalRequest.url ===
      `${BASE_URI}/Identity/Login`) {
      history.push('/login');
      return Promise.reject(error);
    }

    if (error?.response.status === 401 && originalRequest.method ==='post' && originalRequest.url ===
      `${BASE_URI}/User`) {
      return Promise.reject(error);
    }

    if (error?.response.status === 401 && originalRequest.url ===
      `${BASE_URI}/Identity/Refresh`) {
      logout()
      history.push('/login')
      return Promise.reject(error);
    }

    if (error?.response.status === 401 && !originalRequest._retry) {
      let session = JSON.parse(localStorage.getItem(AUTH_USER))
      if (!session) {
        session = JSON.parse(localStorage.getItem(TMP_USER))
      }
      if (!session) {
        return Promise.reject(error)
      }
      const refreshToken = session.refreshToken;
      return axios.post(`${BASE_URI}/Identity/Refresh`, null,
      {
        headers: {
          'Refresh-Token': refreshToken
        }
      })
      .then(res => {
        if (res.status === 201 || res.status === 200) {
          setSession(null, res.data.token, res.data.refreshToken)
          originalRequest._retry = true;
          axios.defaults.headers.common['authorization'] = 'Bearer ' + res.data.token;
          return axios(originalRequest);
        }
      }).catch((error) => {
          originalRequest._retry = false;
          return Promise.reject(error);
      })
  }
  return Promise.reject(error);
});

   const setSession = (username, token, refreshToken) => {
    const jwtHeader = jwt(token);
    const role = jwtHeader['userRole']
    const user = {
      username: username ? username: '',
      token : token,
      refreshToken: refreshToken,
      role : role
    }
     setRole(role)
     setAuthenticated(true);
     localStorage.setItem(AUTH_USER, JSON.stringify(user))
  }

  const createJWTToken = (token) => {
    return 'Bearer ' + token;
  }

  const logout = () => {
    localStorage.removeItem(AUTH_USER);
    setRole('')
    setAuthenticated(false);
  }

  const login = (username,password) => {
    return axios.post(`${BASE_URI}/Identity/Login`, {
      email: username, password
    })
  }

    return (
      <AuthContext.Provider value={{isAuthenticated, login, logout, setSession, role}}>
        {props.children}
      </AuthContext.Provider>
    );
}

export default AuthContextProvider;
