import React, {useState, useEffect, useContext} from 'react';
import './site.css';
import logo from './logo.png';
import step1 from './step1.svg';
import step2 from './step2.svg';
import step3 from './step3.svg';
import step4 from './step4.svg';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/Menu';
import Link from '@material-ui/core/Link';
import { NavLink as RouterLink } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import TextMaskCustom from '../../shared/TextMask'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import {AlertContext} from '../../shared/Contexts/AlertContext'
import axios from 'axios'
import {BASE_URI} from '../../shared/Constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: '#036efd',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));


export default function HomeContainer()
{
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext)
  const [values, setValues] = useState({
    name: '',
    email:'',
    mobileNumber: '',
    description: ''
  });

  const [errors, setError] = useState({
    name: false,
    email: false,
    mobileNumber: false,
    description: false
  })
  const [invalid, setInvalid] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const hasErrors = Object.keys(errors).some(k => errors[k]);
    const hasEmptyValues = Object.keys(values).some(k => values[k] === '');
    if (hasErrors || hasEmptyValues) {
      setInvalid(true)
    } else {
      setInvalid(false)
    }

  },[values,errors])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const validateEmail = () => {
    setError({...errors, email: values.email ==='' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)})
  }

  const submitContactForm = (e) => {
    e.preventDefault()
    if (!invalid) {
      setLoading(true)
      contactUs().then(() => {
        clearForm()
        setLoading(false)
        addAlert(`Thank you for your message ${values.name} we will get back to you shortly.`)
      }).catch(() => {
        setLoading(false)
        addAlert(`Something went wrong please try again later`)
      })
    }
  }

  const clearForm = () => {
    setValues({
      name: '',
      email:'',
      mobileNumber: '',
      description: ''
    })
    setError({
      name: false,
      email: false,
      mobileNumber: false,
      description: false
    })
  }

  const contactUs = () => {
    return axios.post(`${BASE_URI}/User/contactUs`, values)
  }

  const checkError = (prop) => (event) => {
    if (event.target.value === '') {
      setError({...errors, [prop]: true})
    } else {
      setError({...errors, [prop]: false})
    }
  }

  const scrollToElement = (element) =>
  {
    var el = document.getElementById(element);

    if (el != null)
    {
      //80 px is the offset of the navbar because its fixed
      document.documentElement.scrollTop = el.offsetTop - 80;
    }
  }

  return (
    <div style={{overflowX: 'hidden'}}>
      <header>
        <nav className="navbar navbar-expand-lg pl-md-5">
          <a className="navbar-brand" href="#">
            <img src={logo} style={{maxWidth: '250px'}} alt="Avo"/>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbars"
                  aria-controls="navbars" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" style={{paddingTop: '0.4rem'}}>
              <MenuIcon style={{color: 'white'}}/>
            </span>
          </button>

          <div className="collapse navbar-collapse" id="navbars">
            <ul className="navbar-nav ml-auto align-items-lg-center pr-md-5 pb-md-2">
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <a className="nav-link" onClick={() => scrollToElement("hero")}>Home</a>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <a className="nav-link" onClick={() => scrollToElement("benefits")}>Benefits</a>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <a className="nav-link" onClick={() => scrollToElement("steps")}>Process</a>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <a className="nav-link" onClick={() => scrollToElement("faq")}>FAQs</a>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <a className="nav-link" onClick={() => scrollToElement("contact")}>Contact Us</a>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <Link component={RouterLink} to='/login' className="nav-link">
                  Login
                </Link>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <Link
                  component={RouterLink}
                  className="btn btn-secondary nav-btn d-none d-sm-block"
                  to='/sell-car'>
                  <AddIcon/>&nbsp;&nbsp;Let's buy your car
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <section className="homepage-first" id="hero">
        <div className="container">
          <div className="row">

            <div className="col-lg-5 text-left pt-5 pb-5">
              <h2 className="display-4">
                Selling your car for the best price should be easy!
              </h2>
              <p className="pt-4">
                Get the best offer for your used car! 1000’s of Dedicated pre-approved buyers waiting to bid on your car and offer you a great deal.
              </p>
              <Link
                component={RouterLink}
                className="btn btn-secondary btn-lg"
                to='/sell-car'>
                <AddIcon />&nbsp;&nbsp;Let's buy your car
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="homepage-benefits" id="benefits">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-2 p-4 px-5 pt-md-4 pb-md-4 px-md-2">

              <h3><i className="far fa-money-bill-wave"></i><br/>Bids from 5* Rated dealers</h3>
              <p className="mb-0">
                You get bids from multiple buyers on your car. You can choose the offer that is best for you, no obligation.
              </p>
            </div>
            <div className="col-lg-2 p-4 px-5 pt-md-4 pb-md-4 px-md-2">

              <h3><i className="far fa-phone-laptop"></i><br/>Buyer & Seller Experience</h3>
              <p className="mb-0">
                Our simple process of loading & buying of vehicles ensures both parties have the best experience.
              </p>
            </div>
            <div className="col-lg-2 p-4 px-5 pt-md-4 pb-md-4 px-md-2">

              <h3><i className="far fa-id-card"></i><br/>Contact Info</h3>
              <p className="mb-0">
                Contact details are only shared once you
                have accepted the best suited offer made on your vehicle.
              </p>
            </div>
            <div className="col-lg-2 p-4 px-5 pt-md-4 pb-md-4 px-md-2">

              <h3><i className="far fa-star"></i><br/>Ratings</h3>
              <p className="mb-0">
                Your experience with our buyers is important,
                why not rate the buyer after you sold your car.
              </p>
            </div>
            <div className="col-lg-2 p-4 px-5 pt-md-4 pb-md-4 px-md-2">

              <h3><i className="far fa-motorcycle"></i><br/>Wide range</h3>
              <p className="mb-0">
                We also buy Light Commercials and Bikes.
              </p>
            </div>
            <div className="col-lg-1"></div>
          </div>
      </section>

      <section className="homepage-steps py-3 py-md-5" id="steps">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-12 col-md-2 p-4 p-md-0">
              <div className="d-flex align-items-center justify-content-md-around stepContainer">
                <div className="stepTitle">
                  <img src={step1} className="stepImg"  alt="Avo"/>
                  <h4>Load your car</h4>
                </div>
                <i className="far fa-angle-right d-none d-sm-block"></i>
              </div>
            </div>
            <div className="col-12 col-md-2 p-4 p-md-0">
              <div className="d-flex align-items-center justify-content-md-around  stepContainer">
                <div className="stepTitle">
                  <img src={step2} className="stepImg"  alt="Avo"/>
                  <h4>Review Offers</h4>
                </div>
                <i className="far fa-angle-right d-none d-sm-block"></i>
              </div>
            </div>
            <div className="col-12 col-md-3 p-4 p-md-0">
              <div className="d-flex align-items-center justify-content-md-around  stepContainer">
                <div className="stepTitle">
                  <img src={step3} className="stepImg"  alt="Avo"/>
                  <h4>Engage with our dealers</h4>
                </div>
                <i className="far fa-angle-right d-none d-sm-block"></i>
              </div>
            </div>
            <div className="col-12 col-md-3 p-4 p-md-0">
              <div className="d-flex align-items-center justify-content-md-around  stepContainer pr-0">
                <div className="stepTitle">
                  <img src={step4} className="stepImg"  alt="Avo"/>
                  <h4>We are passionate about you!</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
      </section>

      <section className="homepage-faq" id="faq">

        <div className="row" style={{height: '100%'}}>
          <div className="col-lg-7 text-white pt-5" style={{ backgroundColor: '#fff' }}>
            <div className="col-md-8 col-12 mx-auto">
              <h3 className="display-4">Frequently asked questions</h3>
              <div className="accordion mb-5 mt-5" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne" data-toggle="collapse"
                       data-target="#collapseOne"
                       aria-expanded="false"
                       aria-controls="collapseOne">
                    <h5 className="mb-0"> How do I register my car?

                    </h5>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Registration is really easy - just click on "Sell my car" and we will guide you through the process. After adding your car you will get a confirmation email.
                      <br/><br/>
                      Alternatively you could click on register me and create a username and password and add your car later. So easy - you can do either one.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFour" data-toggle="collapse"
                       data-target="#collapseFour"
                       aria-expanded="false"
                       aria-controls="collapseFour">
                    <h5 className="mb-0">

                      Can I request my asking price?

                    </h5>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body text-black">
                      We always show the price you want to each buyer that bids. However, the buyer is free to offer any amount they want for the vehicle. It is entirely up to you to accept or decline.
                      <br/><br/>
                      This allows you to judge the quality of the offer. However, we cannot assure you of the buyer charisma or quality of interaction or customer experience. Should there be a serious violation, we will remove the buyer.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree" data-toggle="collapse"
                       data-target="#collapseThree"
                       aria-expanded="false"
                       aria-controls="collapseThree">
                    <h5 className="mb-0">

                      What are the fees?

                    </h5>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Listing your vehicle through Avo and receiving bids from buyers is free.<br/><br/>

                      We require as much relevant info as possible, that will ensure our dedicated
                      buyers can make you the best possible offer. You are under no obligation to accept
                      any offer, should you not accept a offer on your vehicle – the vehicle will need to
                      be loaded again after 48 hours.
                    </div>
                  </div>
                </div>



                <div className="card">
                  <div className="card-header" id="headingNine" data-toggle="collapse"
                       data-target="#collapseNine"
                       aria-expanded="false"
                       aria-controls="collapseNine">
                    <h5 className="mb-0">

                      Terms & Conditions

                    </h5>
                  </div>
                  <div
                    id="collapseNine"
                    className="collapse"
                    aria-labelledby="headingNine"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p>
                      Avo connects private sellers and buyers (dealerships).
                      Feedback is important to us, thus we provide both parties the
                      opportunity to rate their experience they
                      had with the other party. You will also be able to see other people’s
                      ratings of the dealer you are interacting with. Ratings can assist you in
                        judging the quality of the offers received.</p>

                      <p>However, we cannot assure you of
                      the buyers' charisma or quality of interaction or customer experience.
                        Should there be a serious violation, we will remove the buyer.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>


      <section className="homepage-contactus" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-12">
                <h3 className="display-4">Contact Us</h3>
                <h4 className="pb-3 pt-2">If you want to contact us, please use the form below.</h4>
                <form className="pt-4" onSubmit={submitContactForm}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        onBlur={checkError('name')}
                        error={errors.name}
                        helperText={errors.name ? 'Please enter your name' : ' '}
                        id="name"
                        label="Name"
                        value={values.name}
                        onChange={handleChange('name')}
                      />
                    </Grid>
                    <Grid item md={6} implementation="css" smDown component={Hidden} />


                    <Grid item xs={12} md={6} className="pt-0">
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Mobile Number"
                        required
                        value={values.mobileNumber}
                        onChange={handleChange('mobileNumber')}
                        onBlur={checkError('mobileNumber')}
                        error={errors.mobileNumber}
                        helperText={errors.mobileNumber ? 'Please enter mobile number' : ''}
                        name="mobileNumber"
                        id="mobileNumber"
                        InputProps={{
                          inputComponent: TextMaskCustom,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} className="pt-0">
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Email Address"
                        required
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={validateEmail}
                        error={errors.email}
                        helperText={errors.email ? 'Please enter a valid email' : ''}
                        name="email"
                        id="email"
                      />
                    </Grid>
                    <Grid item xs={12} className="pt-0">
                      <TextField
                        id="description"
                        label="Message"
                        name="description"
                        fullWidth
                        required
                        multiline
                        rows={4}
                        placeholder="Please send us a Message"
                        value={values.description}
                        onChange={handleChange('description')}
                        onBlur={checkError('description')}
                        error={errors.description}
                        helperText={errors.description ? 'Please enter your message to us' : ' '}
                      />
                    </Grid>
                    <Grid item container justify="flex-end">
                      <div className={classes.wrapper}>
                        <Button variant='contained'
                                disabled={invalid || loading}
                                onClick={submitContactForm}
                                color="primary">
                          Contact Us
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                    </Grid>

                  </Grid>
                </form>
            </div>
          </div>
        </div>
      </section>

      <section className="text-white footer" style={{background: '#343a40'}}>
        <div className="container">

          <div className="container-fluid">
            <div className="row pb-2 pt-2" style={{background: '#343a40'}}>
              <div className="col-12 mx-auto text-center">
                Copyright © Avo 2019, a division of the <a href="https://www.c2group.co.za/">C2Group</a>.
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )

}
