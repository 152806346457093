import React, { Component } from 'react';
import NavbarComponent from './NavbarComponent';
import {Route } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import UserCarListingContainer from './UserCarListingContainer';
import LoginComponent from './LoginComponent';
import AuthenticatedRoute from '../shared/AuthenicatedRoute';
import SellCarContainer from './Seller/SellCarContainer'
import BidsContainer from './Dealer/BidsContainer'
import ReviewsContainer from './ReviewsContainer'
import VehicleViewContainer from './VehicleViewContainer'
import {AlertProvider} from '../shared/Contexts/AlertContext'
import LoadingProvider from '../shared/Contexts/LoadingContext'
import {AuthContext} from '../shared/Contexts/AuthContext'
import {BidProvider} from '../shared/Contexts/BidContext'
import HomeComponent from './HomePage/HomeComponent'
import {withRouter} from 'react-router-dom';
import ResetPasswordComponent from './ResetPasswordComponent'
import UserContainer from './UserContainer'
import ContactableContainer from "./Dealer/ContactableContainer"
import AdminReports from './AdminReports'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


class MainContainer extends Component {
  static contextType = AuthContext;

  render () {
    const {pathname} = this.props.location;

    return (
      <React.Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AlertProvider>
            <LoadingProvider>
                {
                  (this.context.isAuthenticated || pathname === '/sell-car') && <NavbarComponent/>
                }
                        <Route path="/" exact component={LoginComponent} />
                        <Route path="/login" exact component={LoginComponent} />
                {/*<Route path ="/myVehicles/success" exact component={HomeComponent}/>*/}
               
                <Route path ="/reset-password/:token?" exact component={ResetPasswordComponent}/>
                <main>
                  <Container className={clsx((this.context.isAuthenticated || pathname === '/sell-car') ?'mt-4 py-lg-4 py-1 px-lg-5 px-3' : '')}>
                    <AuthenticatedRoute path ="/myVehicles/:paymentStatus?" exact component={UserCarListingContainer}/>
                    <AuthenticatedRoute path="/users" exact component={UserContainer}/>
                    <Route path="/sell-car/:vehicleId?"  component={SellCarContainer}/>
                    <BidProvider>
                      <AuthenticatedRoute path ="/vehicle/:id" exact component={VehicleViewContainer}/>
                      <AuthenticatedRoute path="/bids" exact component={BidsContainer}/>
                    </BidProvider>
                    <AuthenticatedRoute path="/reviews"  component={ReviewsContainer}/>
                    <AuthenticatedRoute path="/adminReports"  component={AdminReports}/>
                    {/*<Route path="*" component={NotFoundComponent}/>*/}
                    <AuthenticatedRoute path="/contactable/:vehicleListingId?" exact component={ContactableContainer}/>
                  </Container>
                </main>
            </LoadingProvider>
          </AlertProvider>
        </MuiPickersUtilsProvider>
      </React.Fragment>
    )
  }
}

export const MainComponent = withRouter(props => <MainContainer {...props}/>);