import React, { useEffect, useRef, useContext, useState } from "react";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import AssignmentIcon from '@material-ui/icons/Assignment';
import ReportDialog from './ReportDialog'
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios/index'
import { BASE_URI } from '../../shared/Constants'
import { useParams } from 'react-router-dom'
import { AlertContext } from '../../shared/Contexts/AlertContext'


export default function Reports() {
    const { id } = useParams();

    const fetchReport = () => {

        axios.get(`${BASE_URI}/dashboard/report`, {
            params: {
                vehicleListingId: id
            },
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
    }

    return (
        <Grid container
            className="p-4"
            justify="space-between"
            alignItems="center">
            <Grid item>
                <Typography variant="h6" color="inherit">
                    Reports
        </Typography>
            </Grid>
            <Grid item className="d-flex">

                <Grid item className="d-flex">
                    <Button
                        className="m-2 text-capitalize"
                        color="inherit"
                        onClick={() => fetchReport()}
                        startIcon={<AssignmentIcon />}
                    >
                        Download report
                  </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}