import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MainComponent} from './components/MainContainer'
import { makeStyles } from '@material-ui/core/styles';
import {AuthContextProvider} from './shared/Contexts/AuthContext'
import {BrowserRouter as Router } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import back2 from './components/Assets/back2.jpg'
import { Helmet } from "react-helmet";

const styles = makeStyles(() => ({
  appContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: '100%'
  },
  app: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: '100vw',
    minHeight: '100vh',
    background: 'linear-gradient(180deg, rgba(242,242,244,1) 0%, rgba(160,163,180,1) 60%)',
    backgroundImage: `url(${back2})`,
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
  }
}));

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
      },
    },
    MuiTypography: {
        h5: {
          fontWeight: '500 !important'
        }
    },
    MuiButton: {
      contained: {
        borderRadius: 4,
        padding: '0.6rem 1.5rem',
        color: '#036efd',
        backgroundColor: 'white',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        '&$disabled': {
          background: 'rgba(0, 0, 0, 0.12)',
        }
      },
      containedPrimary: {
        background: 'linear-gradient(90deg, #1794fd 0%, #036efd 50%)',
        color: 'white'
      },
      textSecondary: {
        color: '#9e9e9e'
      },
      containedSecondary: {
        background: 'linear-gradient(90deg, rgba(111,208,32,1) 0%, rgba(77,148,25,1) 50%)'
      }
    },
    MuiCard: {
      root: {
        borderRadius: 10
      }
    }
  },
});

const App = () => {
  const classes = styles();
  const isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

  return (
    <React.Fragment>
      {isIOS && <Helmet><meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" /></Helmet>}
      <ThemeProvider theme={theme}>
        <Router>
          <AuthContextProvider>
          <CssBaseline />
            <div className={classes.appContainer}>
              <div className={classes.app}>
                  <MainComponent />
              </div>
            </div>
          </AuthContextProvider>
        </Router>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default App;
