import React, { useState, useContext } from "react";
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import SellerBidsTable from './SellerBidsTable'
import { Divider } from '@material-ui/core';
import {CurrencyValue} from '../shared/TextMask'
import { useParams} from 'react-router-dom'
import BidDialog from './Seller/BidDialog'
import {BidContext} from '../shared/Contexts/BidContext'
import {AuthContext} from '../shared/Contexts/AuthContext'
import {SELLER_ROLE} from '../shared/Constants'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


export default function VehicleBidContainer({highestBid}) {
  const { id } = useParams();
  const { acceptBid } = useContext(BidContext)
  const [open, setOpen] = useState(false)
  const [bidId, setBidId] = useState(null)
  const { role } = useContext(AuthContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const openAccept = (id) => {
    setBidId(id)
    setOpen(true)
  }

  const handleBidRequest = () => {
    setOpen(false)
    acceptBid(bidId)
  }

  return (
    <React.Fragment>
      <BidDialog statusBid={'Accept'}
                 open={open}
                 processBid={handleBidRequest}
                 closeDialog={() => setOpen(false)}/>
      <Card className="mt-4" style={{width: '100%'}}>
        {
          (highestBid && role === SELLER_ROLE) ? (
            <Grid container
                  style={{padding: '16px'}}
                  alignItems={matches ? 'flex-end': 'center'} justify={matches ? 'flex-end': 'center'}>
              <Grid item className="pr-4">
                <Typography variant="h5">
                  Highest Bid <strong><CurrencyValue value={highestBid.bidAmount} /></strong>
                </Typography>
              </Grid>
              <Grid item className='mt-md-0 mt-3'>
                <Button variant="contained"
                        onClick={() => openAccept(highestBid.bidId)} color="secondary">
                  Accept Bid
                </Button>
              </Grid>
            </Grid>
          ) : null
        }
        <Divider/>
        <CardContent>
          <SellerBidsTable acceptBid={openAccept} id={id}/>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}