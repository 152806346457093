import React, { useState, createContext, useContext } from 'react';
import {LoadingContext} from './LoadingContext'
import {AlertContext} from './AlertContext'
import axios from 'axios'
import {BASE_URI} from '../Constants'

export const BidContext = createContext()

export function BidProvider({ children }) {
  const { addAlert } = useContext(AlertContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const [bidUpdated, setBidUpdated] = useState(false)

  const acceptBid = (bidId) => {
    if (bidId) {
      setBidUpdated(false)
      showLoading()
        axios.post(`${BASE_URI}/bid/acceptBid`, null, {
          params: {
            bidId
          }
        }).then(response => {
          hideLoading()
          setBidUpdated(true)
        }).catch((error) => {
          hideLoading()
          addAlert('Failed to accept bid')
        })

    } else {
      addAlert('Bid Id is required')
    }
  }

  const rejectBid = (bidId, rating) => {
    if (bidId) {
      setBidUpdated(false)
      const dealerRating = {
        rating,
        reviewTags: []
      }
      showLoading()
      axios.post(`${BASE_URI}/bid/reject`, dealerRating, {
        params: {
          bidId
        }
      }).then(response => {
        hideLoading()
        setBidUpdated(true)
      }).catch((error) => {
        hideLoading()
        addAlert('Failed to reject bid')
      })

    } else {
      addAlert('Bid Id is required')
    }
  };

  const concludeDeal = (bidId, finalPrice, rating, reviewTags) => {
    if (bidId) {
      setBidUpdated(false)
      showLoading()
      const conclude = {
        rating: rating ? rating: 0,
        finalPrice,
        reviewTags
      }
      axios.post(`${BASE_URI}/bid/conclude`, conclude, {
        params: {
          bidId
        }
      }).then(response => {
        hideLoading()
        setBidUpdated(true)
      }).catch((error) => {
        hideLoading()
        addAlert('Failed to conclude deal')
      })
    } else {
      addAlert('Bid Id is required')
    }
  }

  const postBid = (vehicleListingId, bidAmount) => {
    //@TODO if required to set highestBid then will have to reload whole component
    if (vehicleListingId) {
      const dealerBid = {
        vehicleListingId: parseInt(vehicleListingId),
        bidAmount
      }
      return axios.post(`${BASE_URI}/bid/`, dealerBid)

    } else {
      addAlert('Vehicle Id is required')
    }
  }

  const putBid = (bidId, bidAmount) => {
    if (bidId) {
      const dealerBid = {
        bidAmount
      }
      return axios.put(`${BASE_URI}/bid/`, dealerBid, {
        params: {
          bidId
        }
      })

    } else {
      addAlert('Bid ID is required')
    }
  }

  const fetchBids = (pageNumber, pageSize) => {
    return axios.get(`${BASE_URI}/bid/`, {
      params: {
        pageNumber,
        pageSize
      }
    })
  }

  const withdrawBid = (bidId) => {
    return axios.delete(`${BASE_URI}/bid/`, {
      params: {
        bidId
      }
    })
  }


  return (
    <BidContext.Provider value={{acceptBid, rejectBid, concludeDeal, postBid, putBid, fetchBids, withdrawBid, bidUpdated, setBidUpdated}}>
      {children}
    </BidContext.Provider>
  )
}