import React, {useState, useContext}  from "react";
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from '@material-ui/core/styles/index'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton'
import {CurrencyConverter, CurrencyValue} from '../../shared/TextMask'
import DoneIcon from '@material-ui/icons/Done';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import EditBidDialog from './EditBidDialog'
import WithdrawDialog from './WithdrawDialog'
import {useHistory} from 'react-router-dom'
import {BidContext} from '../../shared/Contexts/BidContext'
import {LoadingContext} from '../../shared/Contexts/LoadingContext'
import {AlertContext} from '../../shared/Contexts/AlertContext'
import Image from 'material-ui-image'
import TablePagination from '@material-ui/core/TablePagination';
import BidsFilter from '../../shared/BidsFilter'
import CircularProgress from '@material-ui/core/CircularProgress';
import {PAGE_SIZE} from '../../shared/Constants'
import Grid from '@material-ui/core/Grid'
import TableHeadersSort from '../../shared/TableHeadersSort'


const useStyles = makeStyles(() => ({
  tableHeader: {
    borderBottom: '2px solid darkgray'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}));

export default function DealerBidsTable({status}) {
  let history = useHistory();
  const localStyles = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openWithdraw, setWithdraw] = React.useState(false);
  const [forceReload, setForceReload] = useState(false);
  const [activeVehicle, setActiveVehicle] = React.useState('');
  const [anchorRef, setAnchorRef] = useState(null);
  const { putBid, withdrawBid: withdrawBidRequest  } = useContext(BidContext)
  const { addAlert } = useContext(AlertContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('dateOfBid');

  const{
    loading,
    bids,
    total
  }= BidsFilter(page, pageSize, status, order, orderBy, forceReload)

  const checkStatus = (status) => {
    switch (status.toLowerCase()) {
      case 'accepted':
        return ({color: '#498d20'})
      case 'concluded':
        return ({color: '#498d20'})
      case 'active':
        return ({color: 'darkgrey'})
      case 'lost':
        return ({color: '#cf4027'})
      default:
        return ({color: '#000'})
    }
  }

  const handleMenuToggle = (event, id, vehicleName, amount, status, bidId) => {
    let tmpVehicle = {
      id,
      vehicleName,
      amount,
      status,
      bidId
    }
    setActiveVehicle(tmpVehicle)
    setAnchorRef(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  }

  const handleMenuClose = () => {
    setOpen(false);
    if (anchorRef) {
      setAnchorRef(null);
    }
  }

  const carNameClick = (event, id, vehicleName, amount, status, bidId) => {
    let tmpVehicle = {
      id,
      vehicleName,
      amount,
      status,
      bidId
    }
    setActiveVehicle(tmpVehicle)

    const newLocation = {
      pathname: `/vehicle/${id}`,
      state: { from: 'Bids' }
    }
    history.push(newLocation)
  }

  const viewCar = () => {
    const newLocation = {
      pathname: `/vehicle/${activeVehicle.id}`,
      state: { from: 'Bids' }
    }
    history.push(newLocation)
    handleMenuClose()
  };

  const editBid = () => {
    setOpenEdit(true)
    handleMenuClose()
  }

  const openWithdrawBid = () => {
    setWithdraw(true)
    handleMenuClose()
  }

  const withdrawBid = () => {
    setWithdraw(false)
    if (activeVehicle.bidId) {
      showLoading()
      withdrawBidRequest(activeVehicle.bidId).then((response) => {
        hideLoading()
        setPage(0)
        addAlert(`Withdrew bid for ${activeVehicle.vehicleName}`)
      }).catch((error) => {
        hideLoading()
        addAlert(`Unable to withdraw bid for ${activeVehicle.vehicleName}`)
      })
    } else {
      addAlert('Bid ID is required')
    }
  }

  const editBidAmount = (amount) => {
    const index = bids.findIndex((bid) => {
      return bid.vehicleListingId === activeVehicle.id
    });
    let tmpBidsArr = [...bids]
    let tmpBid = {...tmpBidsArr[index]}
    const newAmount = CurrencyConverter(amount)
    if (newAmount !== tmpBid.bidAmount) {
      showLoading()
      putBid(activeVehicle.bidId, newAmount).then(() => {
        hideLoading()
        setForceReload(prev => !prev)
        setPage(0)
        addAlert(`Successfully edited bid to R${newAmount} for ${activeVehicle.vehicleName}`)
      }).catch((error) => {
        hideLoading()
        addAlert(`Unable to edit bid for ${activeVehicle.vehicleName}`)
      })
    }
    setOpenEdit(false)
  }

  const carImageContainer = {
    height: 40,
    width: 40,
    paddingTop: 0,
    marginRight: 20
  }

  const carImage = {
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    borderRadius: '50%'
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const headCells = [
    { id: 'vehicleName', numeric: false, width: '30%', label: 'Car Name', sort: true },
    { id: 'status', numeric: false, label: 'Status', sort: true },
    { id: 'dateOfBid', numeric: false, label: 'Date Added', sort: true },
    { id: 'highestBid', numeric: false, label: 'Highest Bid', sort: true },
    { id: 'amount', numeric: false, label: 'Your Bid', sort: true },
    { id: 'action', numeric: false, label: ' ', sort: false },
  ];


  return (
    <TableContainer component={Paper}>
      <EditBidDialog vehicleName={activeVehicle.vehicleName}
                     open={openEdit}
                     confirm={editBidAmount}
                     amount={activeVehicle.amount}
                     closeDialog={()=>setOpenEdit(false)} />
      <WithdrawDialog vehicleName={activeVehicle.vehicleName}
                     open={openWithdraw}
                     confirm={withdrawBid}
                     closeDialog={()=>setWithdraw(false)} />
      <Table aria-label="bids table" size="small">
        <TableHeadersSort
          classes={localStyles}
          order={order}
          orderBy={orderBy}
          headCells={headCells}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
        {
          bids.map((row) => (
          <TableRow key={row.vehicleListingId}>
            <TableCell>
              <Grid container spacing={0} alignItems="center">
                <Grid item>
                  <Image
                    imageStyle={carImage}
                    style={carImageContainer}
                    src={row.vehicleImage} alt={row.vehicleName}/>
                </Grid>
                <Grid item onClick={e => carNameClick(e, row.vehicleListingId, row.vehicleName, row.bidAmount, row.bidStatus, row.bidId)} style={{cursor: 'pointer'}}>
                  {row.vehicleName}
                </Grid>
              </Grid>
            </TableCell>
            <TableCell className='text-capitalize' style={checkStatus(row.bidStatus)}>
              {
                row.bidStatus.toLowerCase() === 'concluded' && <DoneIcon className='mb-1'/>
              }
              {row.bidStatus.toLowerCase()}
            </TableCell>
            <TableCell>{row.bidDate}</TableCell>
            <TableCell>
              <CurrencyValue value={row.highestBid} />
            </TableCell>
            <TableCell>
              <CurrencyValue value={row.bidAmount} />
            </TableCell>
            <TableCell className="pr-0"  style={{width: '5%'}}>
              <IconButton aria-label="options"
                          onClick={(e) => handleMenuToggle(e, row.vehicleListingId, row.vehicleName, row.bidAmount, row.bidStatus, row.bidId)}>
                <MoreVertIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        {
          ((!bids.length || bids.length === 0) && !loading) &&
          <TableRow>
            <TableCell colSpan={6} align="center"
                       style={{borderBottom: 0}}
                       className="py-3">
              <Typography variant="h6" color="textSecondary">
                No {status ? status: ''} bids
              </Typography>
            </TableCell>
          </TableRow>
        }
        {
          loading &&
          <TableRow>
            <TableCell colSpan={6} align="center"
                       style={{borderBottom: 0}}
                       className="py-3">
              <CircularProgress />
            </TableCell>
          </TableRow>
        }
      </TableBody>
      </Table>
      <Popper open={open} anchorEl={anchorRef} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="split-button-menu">
                  <MenuItem onClick={(event) => viewCar(event)}>
                    View Car
                  </MenuItem>
                  {
                    activeVehicle.status.toLowerCase() === 'active' &&
                    <MenuItem onClick={editBid}>
                      Edit Bid
                    </MenuItem>
                  }
                  {
                    activeVehicle.status.toLowerCase() === 'active' &&
                    <MenuItem onClick={openWithdrawBid}>
                      Withdraw Bid
                    </MenuItem>
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <TablePagination
        component="div"
        count={total}
        page={page}
        rowsPerPageOptions={[5,10,25]}
        onChangePage={handleChangePage}
        rowsPerPage={pageSize}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}