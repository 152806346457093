import React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Avatar from '@material-ui/core/Avatar';
import {makeStyles} from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check';
import {useHistory} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

const useStyles = makeStyles((theme) => ({
  dialogSpacing: {
    color: 'white',
    padding: '40px 50px 50px 50px'
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(10),
    height: theme.spacing(10),
    fontSize: '2rem',
    border: '2px solid',
    backgroundColor: '#fafafa',
    marginBottom: '1.5rem',
    marginTop : '1.5rem'
  },
  medium: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  title: {
    fontWeight: 600,
    color: 'white'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function PaymentConfirmation({open, closeDialog, paymentStatus = 'default'}) {
  const classes = useStyles();
  let history = useHistory();

  const checkStatus = (status) => {
    switch (status.toLowerCase()) {
      case 'success':
        return ({backgroundColor: '#1794fd'})
      case 'reject':
        return ({backgroundColor: '#cf4027'})
      default:
        return ({backgroundColor: '#e0e0e0'})
    }
  }

  const borderColor = (status) => {
    switch (status.toLowerCase()) {
      case 'success':
        return ({borderColor: '#0268bd'})
      case 'reject':
        return ({borderColor: '#7d1300'})
      default:
        return ({borderColor: '#9e9e9e'})
    }
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      onClose={closeDialog}
      open={open}>
      <DialogContent style={checkStatus(paymentStatus)} className={classes.dialogSpacing}>
        <CloseIcon
          style={{color: paymentStatus === 'pending' ? '#9e9e9e': 'white'}}
          className={classes.closeButton} onClick={closeDialog} />
        <Grid container
              direction="column"
              justify="center"
              alignItems="center">
          <Grid item>
            <Avatar className={classes.avatar} style={borderColor(paymentStatus)}>
            {
              paymentStatus === 'success' && <CheckIcon className={classes.medium} style={{color: '#1794fd'}}/>
            }
            {
              paymentStatus === 'reject' && <CloseIcon className={classes.medium} style={{color: '#cf4027'}}/>
            }
            {
              paymentStatus === 'pending' && <HourglassEmptyIcon className={classes.medium} style={{color: '#bdbdbd'}}/>
            }
            </Avatar>
          </Grid>
          <Grid item container
          justify="center"
          alignItems="center"
          spacing={3}
          direction="column">
            <Typography variant="h6" className={classes.title} gutterBottom style={{color: paymentStatus === 'pending' ? '#9e9e9e': 'white'}}>
              { paymentStatus === 'success' ? 'Payment Confirmed!' : paymentStatus === 'reject' ? 'Payment Declined!' : 'Payment Pending'}
            </Typography>
            {
              (paymentStatus === 'success' || paymentStatus === 'pending') &&
              <Typography variant="body2"
                          style={paymentStatus === 'pending' ? {color: '#9e9e9e'}: {color: 'white'}}
                          className='my-3 text-center' gutterBottom component="p">
                <strong>Thank You for your purchase! You will receive a notification once your payment has reflected</strong>
              </Typography>
            }
            {
              paymentStatus === 'success' &&
                <React.Fragment>
                  <Button variant="contained"
                          onClick={()=> history.push(`/sell-car`)} color="secondary">
                    Create a new listing
                  </Button>
                </React.Fragment>
            }
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
