import React, { useEffect,useContext } from 'react';
import {BASE_URI} from '../../shared/Constants'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {LoadingContext} from '../../shared/Contexts/LoadingContext'
import {AlertContext} from '../../shared/Contexts/AlertContext'


export default function ContactableContainer(props){
    const {vehicleListingId} = useParams()
    const { addAlert } = useContext(AlertContext)
    const { showLoading, hideLoading } = useContext(LoadingContext)

    const [reportInfo, setReportInfo] = React.useState({});
    const [isLoading, setLoading] = React.useState(false);

    const GetContactableReport = (vehicleListingId) => {
        setLoading(true)
        showLoading()
        axios.get(`${BASE_URI}/dashboard/report/contactableReport?vehicleListingId=${vehicleListingId}`)
        .then( res => {
            setReportInfo(res.data)
            setLoading(false)
            hideLoading()

        }).catch((error) => {
            hideLoading()
            addAlert(`${error}`)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (vehicleListingId) {
            GetContactableReport(vehicleListingId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [vehicleListingId])

      const renderPage = () => {
          if(!isLoading)
            return <React.Fragment>
                        <div className="card" style={{width: 52 + "rem"}}>
                        <div className="card-body">
                            <h5 className="card-title">{reportInfo.Message}</h5>
                            <hr></hr>
                                <br/>
                            <h6 className="card-subtitle mb-2 text-muted">Report Information</h6>
                                <br/>
                            <p className="card-text">Report successful : { reportInfo.result ? "True" : "False"}</p>
                            <p className="card-text">ID Validation passed : { reportInfo.validationPassed ? "True" : "False"}</p>
                            <p className="card-text">Facial detection match : { reportInfo.faceMatch ? "True" : "False"}</p>
                                <br/>
                                <br/>
                            <h6 className="card-subtitle mb-2 text-muted">Confirmation of users personal information</h6>
                            <p className="card-text">Full name : { reportInfo.firstName} "{reportInfo.initials}" {reportInfo.surname}</p>
                            <p className="card-text">Deceased status : {reportInfo.deceasedStatus}</p>
                                <br/>
                        </div>
                        </div>
                    </React.Fragment>
          
      }

  return (

<React.Fragment>
    {renderPage()}
</React.Fragment>

);
}