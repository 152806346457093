import {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {BASE_URI, PAGE_SIZE} from './Constants'
import {AlertContext} from './Contexts/AlertContext'

export default function ReviewFilter(pageNumber) {
  const { addAlert } = useContext(AlertContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [reviews, setReviews] = useState([])
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    let cancel
    setLoading(true)
    setError(false)
    const params = {
      pageNumber,
      pageSize: PAGE_SIZE
    }
    axios({
      method: 'GET',
      url: `${BASE_URI}/bid/reviews/tags`,
      params,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {
      setReviews(prevReviews => {
        let combinedArray = [...new Set([...prevReviews, ...response.data?.reviews])]
        const removedDuplicates= combinedArray.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
        return removedDuplicates
      })
      setHasMore(response.data?.reviews.length > 0)
      setLoading(false)
    }).catch( error => {
      if(axios.isCancel(error)) return
      if (error.response) {
        addAlert('Cannot perform query')
        setError(true)
      }
    })
    return () => cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber])

  return {loading, error, reviews, hasMore}
}