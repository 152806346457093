import React, {useEffect, useContext} from 'react';
import Grid from '@material-ui/core/Grid';
import {useHistory, useParams, useLocation} from 'react-router-dom'
import {AuthContext} from '../shared/Contexts/AuthContext'
import {ADMIN_ROLE, BASE_URI, DEALER_ROLE, SELLER_ROLE} from '../shared/Constants'
import SellerVehicleActions from './Seller/SellerVehicleActions'
import VehicleView from './VehicleView'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import {LoadingContext} from '../shared/Contexts/LoadingContext'
import {AlertContext} from '../shared/Contexts/AlertContext'
import axios from 'axios'
import {BidContext} from '../shared/Contexts/BidContext'


const useStyles = makeStyles((theme) => ({
  linkTxt: {
    color: '#f18f3d'
  }
}));

export default function VehicleViewContainer() {
  const localStyles = useStyles();
  const { role } = useContext(AuthContext);
  const { bidUpdated } = useContext(BidContext);
  let history = useHistory();
  let location = useLocation();
  const { id } = useParams();
  const [prevLocation, setPrevLocation] = React.useState(null)
  const { addAlert } = useContext(AlertContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const [vehicleInfo, setVehicleInfo] = React.useState({});
  const [state, setState] = React.useState('Active')
  const [isLoading, setLoading] = React.useState(false)

  useEffect(() => {
    if (bidUpdated) {
      fetchVehicleListing()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bidUpdated])

  useEffect(() => {
    if (location && location.state) {
      setPrevLocation(location.state.from)
    }
    if (id) {
      fetchVehicleListing()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const fetchVehicleListing = () => {
    setLoading(true)
    showLoading()
      axios.get(`${BASE_URI}/vehicle`, {
        params: {
          vehicleListingId: id
        }
      }).then(response => {
        setVehicleInfo(response.data)
        setState(response.data.state)
        setLoading(false)
        hideLoading()
      }).catch( error => {
        hideLoading()
        addAlert('Unable to find vehicle')
        setLoading(false)
        history.push(`/myVehicles`)
      })
  }

  return (
    <Grid container direction="column">
      <Grid item>
        {
          prevLocation &&
          <Button className={localStyles.linkTxt}
                  onClick={()=>history.goBack()}>Go Back to {prevLocation}</Button>
        }
      </Grid>
      {
        !isLoading &&
        <React.Fragment>
          {
            ((role === SELLER_ROLE || role === ADMIN_ROLE) && Object.keys(vehicleInfo).length > 0) &&
            <SellerVehicleActions
              vehicleState={state}
              vehicleDetails={vehicleInfo}
              bidCount={vehicleInfo.bidCount}  />
          }
          {
            (role === DEALER_ROLE && Object.keys(vehicleInfo).length > 0) &&
            <VehicleView vehicleDetailsProp={vehicleInfo} vehicleState={state}/>
          }
        </React.Fragment>
      }
    </Grid>
  );
}