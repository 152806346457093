import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {StyledDialogTitle} from '../shared/StyledDialogs'
import {useHistory} from 'react-router-dom'


export default function PendingPaymentDialog({open, closeDialog }) {
  let history = useHistory();

  return (
    <Dialog maxWidth='xs'
            onExit={() => history.push(`/myVehicles`)}
            onClose={closeDialog}  open={open}>
      <StyledDialogTitle onClose={closeDialog}>
        Payment Processing
      </StyledDialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Your payment is still being processed, a notification will be sent to you the moment
          this action is available.
        </Typography>
      </DialogContent>
      <DialogActions className="py-3 px-3">
        <Button color="secondary" onClick={closeDialog}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )

}
