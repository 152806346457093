import React, {useEffect, useContext} from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import GlobalStyles from '../../GlobalStyles';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import ImageUploader from '../../shared/ImageUploader';
import ImageService from '../../shared/ImageService';
import {BASE_URI, IMAGE_UPLOADER_CONFIG} from '../../shared/Constants'
import axios from 'axios'
import {AlertContext} from '../../shared/Contexts/AlertContext'
import {LoadingContext} from '../../shared/Contexts/LoadingContext'
import Resizer from "react-image-file-resizer"



export default function VehicleImageForm({images, onSubmit, id}) {
  const classes = GlobalStyles();
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { addAlert } = useContext(AlertContext)
  const [values, setValues] = React.useState([]);
  const [errors, setError] = React.useState({
    thumbnail: false,
    rear: false,
    front: false,
    leftside: false,
    rightside: false,
    interiorodometer: false,
    interiorcenter: false
  })

  useEffect(() => {
    if (images) {
      setValues(images)
    } else {
      setValues([])
    }
  },[images])

  const submitForm = (e) => {
   
      showLoading()
      
      postVehicleImages().then((response) => {
        onSubmit(values)
        hideLoading()
    
      }).catch((error) => {
        hideLoading()
        addAlert('Error uploading images')
      })
  }

  const postVehicleImages = () => {
    const convertedImages = parseImages()
    return axios.put(`${BASE_URI}/Vehicle/image`, convertedImages,{
      params: {
        vehicleListingId: id
      }
    })
  }

  const parseImages = () => {
    let editedArr = values.filter((image) => image.image)
    return editedArr.map((image) => {
          return({
            image: ImageService.parseBase64(image.image),
            imageType: image.imageType
          })
    })
  }

  const imageUpload = (props, id) => {

    Resizer.imageFileResizer(
      props,
      IMAGE_UPLOADER_CONFIG.maxWidth,
      IMAGE_UPLOADER_CONFIG.maxHeight,
      IMAGE_UPLOADER_CONFIG.compressFormat,
      IMAGE_UPLOADER_CONFIG.quality,
      IMAGE_UPLOADER_CONFIG.rotation,
      uri => {
        const image = {
          image: uri,
          imageType: id
        }
        setValues([...values, image])
        errors[id] = false;
        setError({...errors})
      },
      IMAGE_UPLOADER_CONFIG.outputType
    );
  }

  const clearImage = (id) => {
    let imgArray = values.filter(img => img.imageType !== id);
    setValues(imgArray);
  }

  return (
    <Card>
      <CardHeader className="p-4" title={'Submit images / photos of your vehicle'}/>
      <form onSubmit={submitForm} autoComplete="off">
        <CardContent className="px-5">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <ImageUploader title='Main Image'
                               image={values.find(o => o.imageType.toLowerCase() === 'thumbnail')}
                               id='thumbnail'
                               error={errors.thumbnail}
                               onDropFile={imageUpload}
                               clear={clearImage} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ImageUploader title='Front'
                               image={values.find(o => o.imageType.toLowerCase() === 'front')}
                               id='front'
                               error={errors.front}
                               onDropFile={imageUpload}
                               clear={clearImage} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ImageUploader title='Rear'
                               id='rear'
                               error={errors.rear}
                               image={values.find(o => o.imageType.toLowerCase() === 'rear')}
                               onDropFile={imageUpload}
                               clear={clearImage} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ImageUploader title='Left Side'
                               image={values.find(o => o.imageType.toLowerCase() === 'leftside')}
                               id='leftside'
                               error={errors.leftside}
                               onDropFile={imageUpload}
                               clear={clearImage} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ImageUploader title='Right Side'
                               image={values.find(o => o.imageType.toLowerCase() === 'rightside')}
                               id='rightside'
                               error={errors.rightside}
                               onDropFile={imageUpload}
                               clear={clearImage} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ImageUploader title='Interior - Center Console'
                               image={values.find(o => o.imageType.toLowerCase() === 'interiorcenter')}
                               id='interiorcenter'
                               error={errors.interiorcenter}
                               onDropFile={imageUpload}
                               clear={clearImage} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ImageUploader title='Interior - Odometer'
                               image={values.find(o => o.imageType.toLowerCase() === 'interiorodometer')}
                               id='interiorodometer'
                               error={errors.interiorodometer}
                               onDropFile={imageUpload}
                               clear={clearImage} />
              </Grid>
            </Grid>
        </CardContent>
        <CardActions className={classes.greyBackground}>
          <Grid container justify="flex-end">
                      <Button variant='contained' onClick={submitForm} color="primary">
              Next
            </Button>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
}
