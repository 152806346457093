import React, { useEffect, useContext, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PersonalDetailsForm from './PersonalDetailsForm';
import VehicleSpecsForm from './VehicleSpecsForm'
import VehicleImageForm from './VehicleImageForm'
import ConfirmationContainer from './ConfirmationContainer'
import { StyledTab, StyledTabs } from '../../shared/StyledTabs'
import { useParams } from 'react-router-dom'
import { ADMIN_ROLE, BASE_URI, TMP_USER } from '../../shared/Constants'
import axios from 'axios'
import { LoadingContext } from '../../shared/Contexts/LoadingContext'
import { AlertContext } from '../../shared/Contexts/AlertContext'
import { AuthContext } from '../../shared/Contexts/AuthContext'
import NewListingDialog from './NewListingDialog'
import PendingPaymentDialog from '../../shared/PendingPaymentDialog'


export default function SellCarContainer({ history }) {
    const { vehicleId } = useParams();
    const [id, setId] = React.useState(null)
    const [vehicleDetails, setVehicleDetails] = React.useState(null)
    const [vehicleImages, setVehicleImages] = React.useState(null)
    const { showLoading, hideLoading } = useContext(LoadingContext)
    const { addAlert } = useContext(AlertContext)
    const { isAuthenticated } = useContext(AuthContext);
    const [tabIndex, setTabIndex] = React.useState(0);
    const mountedRef = useRef(true)
    const [verifiedSeller, setVerifiedSeller] = React.useState(null)
    const [listingCount, setListingCount] = useState(0)
    const { role } = useContext(AuthContext);
    const [openNewDialog, setNewDialog] = useState(false)
    const [openPaymentProcessing, setPaymentProcessing] = useState(false)

    useEffect(() => {
        let tmpUser = JSON.parse(localStorage.getItem(TMP_USER))
        setVerifiedSeller(tmpUser !== null || isAuthenticated)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const getPersonalDetails = async () => {
            try {
                const result = await fetchPersonalDetails(source)
                setListingCount(result.data?.numAvailableListings)
                if (vehicleId === null || vehicleId === undefined) {
                    if (result.data?.numAvailableListings <= 0 && !result.data?.paymentPending) {
                        setNewDialog(true)
                    } else if (result.data?.paymentPending) {
                        setPaymentProcessing(true)
                    }
                }
            } catch (error) {
                if (error.response) {
                    addAlert('Error fetching personal details')
                }
            }
        }
        if (isAuthenticated && role !== ADMIN_ROLE) {
            getPersonalDetails().then(() => {
                if (!mountedRef.current) return null
            })
        }
        return () => {
            mountedRef.current = false
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, role])

    const fetchPersonalDetails = (source) => {
        return axios.get(`${BASE_URI}/user`, {
            cancelToken: source.token
        })
    }

    useEffect(() => {
        if (vehicleId) {
            setVerifiedSeller(true)
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            showLoading()
            setId(vehicleId)
            const getVehicle = async () => {
                try {
                    const response = await axios.get(`${BASE_URI}/vehicle/edit`, {
                        params: {
                            vehicleListingId: vehicleId
                        },
                        cancelToken: source.token
                    })
                    const data = response.data
                    const vehicle = {
                        color: data.color,
                        fuelType: data.fuelType,
                        licenseDisk: data.licenseDisk,
                        maintenancePlan: data.maintenancePlan,
                        odometer: data.odometer,
                        registration: data.registration,
                        serviceBook: data.serviceBook,
                        spareKeys: data.spareKeys,
                        transmissionType: data.transmissionType,
                        vehicleVariantId: data.vehicleVariantId,
                        vin: data.vin,
                        warranty: data.warranty,
                        year: data.year,
                        make: data.make,
                        model: data.model,
                        makeId: data.makeId,
                        modelId: data.modelId,
                        category: data.categoryName,
                        vehicleTypeId: data.vehicleTypeId
                    }
                    setVehicleDetails(vehicle)
                    setVehicleImages(data.imgArr)
                    hideLoading()
                } catch (error) {
                    hideLoading()
                    addAlert(error.response.data.error)
                }
            }
            getVehicle().then(() => {
                if (!mountedRef.current) return null
            })
            return () => {
                mountedRef.current = false
                source.cancel();
            };
        } else {
            setId(null)
            setVehicleDetails(null)
            setVehicleImages(null)
            setTabIndex(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleId])

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const onSubmitVehicleSpecs = (vehicleData) => {
        setVerifiedSeller(true)
        setVehicleDetails(vehicleData)
        changeIndex()
    }

    const changeIndex = () => {
        setTabIndex(tabIndex + 1)
    }

    const newSellerSubmit = (submittedId) => {
        setId(submittedId)
        setVerifiedSeller(true)
        changeIndex()
    }

    const updateVehicleImages = (images) => {
        if (images && role !== ADMIN_ROLE) {
            setVehicleImages(images)
            changeIndex()
        } else if (role === ADMIN_ROLE && vehicleId) {
            history.push(`/vehicle/${vehicleId}`)
        } else if (role === ADMIN_ROLE) {
            history.push(`/myVehicles`)
        }
    }

    const tabs = [
        { id: 0, label: 'Vehicle Specs', disabled: true, hide: false, comp: <VehicleSpecsForm vehicleId={id} vehicle={vehicleDetails} onSubmit={onSubmitVehicleSpecs} /> },
        { id: 1, label: 'About you', disabled: true, hide: isAuthenticated, comp: <PersonalDetailsForm onSubmit={newSellerSubmit} /> },
        { id: 2, label: 'Photos', disabled: !id, hide: false, comp: <VehicleImageForm images={vehicleImages} onSubmit={updateVehicleImages} id={id} /> },
        {
            id: 3,
            label: 'Vehicle Summary',
            disabled: !id || (vehicleImages && vehicleImages.length === 0),
            hide: role === ADMIN_ROLE,
            comp: <ConfirmationContainer onSubmit={() => history.push(`/`)} id={id} />
        }
    ];

    return (
        <Grid container direction="column">
            <NewListingDialog open={openNewDialog}
                listingCount={listingCount}
                action={() => setNewDialog(false)}
                closeDialog={() => setNewDialog(false)}
                forceRedirect={true} />
            <PendingPaymentDialog open={openPaymentProcessing}
                closeDialog={() => setPaymentProcessing(false)} />
            <Grid container spacing={2} justify='space-between'>
                <Grid item>
                    <Typography variant="h4" gutterBottom>
                        Sell Car
          </Typography>
                </Grid>
            </Grid>
            <div className="mb-4">
                <StyledTabs variant="scrollable"
                    scrollButtons="on" value={tabIndex} onChange={handleTabChange}>
                    {
                        tabs.filter(tab => !tab.hide).map((step, index) =>
                            <StyledTab label={`Step ${index + 1} - ${step.label}`}
                                key={step.id} disabled={step.disabled} />
                        )
                    }
                </StyledTabs>
            </div>
            {
                tabs.filter(tab => !tab.hide).map((step, index) => {
                    return <React.Fragment key={step.id}> {tabIndex === index ? step.comp : null} </React.Fragment>
                })
            }
        </Grid>
    );

}
