import {makeStyles} from '@material-ui/core/styles';


const GlobalStyles = makeStyles({
  buttonRed: {
    background: 'linear-gradient(90deg, #ce4026 0%, #932a11 50%)',
    padding: '0.6rem 1.5rem !important'
  },
  buttonGreen: {
    padding: '0.6rem 1.5rem !important',
    background: 'linear-gradient(90deg, rgba(111,208,32,1) 0%, rgba(77,148,25,1) 50%)'
  },
  greyBackground: {
    background: '#e8e8e8',
    padding: '1.5rem !important'
  }
});

export default GlobalStyles;