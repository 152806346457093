import React, {useState, useContext, useEffect, useRef, useCallback} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CarCard from '../shared/CarCard';
import {AuthContext} from '../shared/Contexts/AuthContext'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton'
import {ADMIN_ROLE, BASE_URI, DEALER_ROLE} from '../shared/Constants'
import axios from 'axios'
import {AlertContext} from '../shared/Contexts/AlertContext'
import CarFilter from '../shared/CarFilter'
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationFilter from '../shared/LocationFilter'
import PaymentConfirmation from '../shared/PaymentConfirmation'
import {useParams} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
  inputControls: {
    marginBottom: '1rem',
    [theme.breakpoints.down("md")]: {
      background: 'rgba(255,255,255, 0.8)',
      borderRadius: '10px',
      padding: '15px'
    }
  }
}))

export default function UserCarListingContainer(props) {
  const styles = useStyles();
  const { role } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext)
  const [make, setMakes] = useState([])
  const [filterIds, setFilterIds] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [sortDirection, setSortDirection] = useState('Desc')
  const [model, setModels] = useState([])
  const [filterName, setFilterName] = useState({
    make: '',
    model: '',
    emailAddress: '',
    vehicleId: ''
  })
  const [pageNumber, setPageNumber] = useState(1)
  const{
    loading,
    vehicles,
    hasMore
  }=CarFilter(filterIds, pageNumber, sortDirection)
  const observer = useRef()
  const lastVehicleRef = useCallback(node => {
    if (loading) return
    if (observer.current) {
      observer.current.disconnect()
    }
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) {
      observer.current.observe(node)
    }
  }, [loading, hasMore])

  const [openLocation, setOpenLocation] = React.useState(false);
  const [locationQuery, setLocationQuery] = useState('')
  const [locationValue, setLocationValue] = React.useState(null);
  const { paymentStatus } = useParams();
  const [openPaymentDialog, setPaymentDialog] = useState(false)

  const{
    loadingLocation,
    locations
  }=LocationFilter(locationQuery)

  function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }


  useEffect(()=> {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getMake = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/vehicle/make`, {
          params: {
            vehicleTypeId: 0
          },
          cancelToken : source.token
        })
        setMakes(result.data.sort(compareValues('manufacturerName')))
      } catch (error) {
        addAlert('Unable to load vehicle makes')
      }
    }
    getMake()
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(paymentStatus) {
      let paymentStatusLowerCase = paymentStatus.toLowerCase()
      if (paymentStatusLowerCase === 'success' || paymentStatusLowerCase === 'reject' || paymentStatusLowerCase === 'pending') {
        setPaymentDialog(true)
      }
    }
  }, [paymentStatus])


  useEffect(() => {
    if (filterIds.make) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const getModel = async () => {
        try {
          const result = await axios.get(`${BASE_URI}/Vehicle/model`, {
            params: {
              vehicleMakeId: filterIds.make,
            },
            cancelToken : source.token
          })
          setModels(result.data.sort(compareValues('modelName')))
          setDisabled(false)
        } catch (error) {
          if (error.response) {
            addAlert(`Unable to fetch the models for ${filterName.make}`)
          }
        }
      }
      getModel()
      return () => {
        source.cancel();
      };
    } else {
      setDisabled(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterIds.make])

  const handleMakeChange = (event, child) => {
    setFilterIds({...filterIds, make: child.props.id})
    setFilterName({ ...filterName, make: event.target.value});
    setPageNumber(1)
  };

  const handleModelChange = (event, child) => {
    setFilterIds({...filterIds, model: child.props.id})
    setFilterName({...filterName, model: event.target.value})
    setPageNumber(1)
  }

  const handleLocationChange = (event, values) => {
    setFilterIds({...filterIds, location: values ? values.city: ''})
    setLocationValue(values)
    setPageNumber(1)
  }

  const handleEmailChange = (event) => {
    setFilterIds({...filterIds, emailAddress: event.target.value})
    setFilterName({...filterName, emailAddress: event.target.value})
    setPageNumber(1)
  }

  const handleVehicleId = (event) => {
    setFilterIds({...filterIds, vehicleId: event.target.value})
    setFilterName({...filterName, vehicleId: event.target.value})
    setPageNumber(1)
  }

  const navigateToVehicle = (id) => {
    const newLocation = {
      pathname: `/vehicle/${id}`,
      state: { from: 'Vehicle Listing' }
    }
    props.history.push(newLocation)
  }

  const clearFilters = () => {
    setFilterName({
      make: '',
      model: '',
      emailAddress: '',
      vehicleId: ''
    })
    setFilterIds('')
    setLocationValue(null)
    setLocationQuery('')
    setDisabled(true)
    setPageNumber(1)
  }

  return (
    <React.Fragment>
    <Grid container direction="column">
      <Grid container spacing={2} justify='space-between' className="mb-3">
        <Grid item>
          <Typography variant="h4" className="font-weight-bold" gutterBottom>
            {(role === DEALER_ROLE || role === ADMIN_ROLE) ? 'Listed Vehicles': 'My Listed Vehicles'}
          </Typography>
        </Grid>
      </Grid>
      <div className={role === DEALER_ROLE || role === ADMIN_ROLE ? styles.inputControls: ''}>
      {
        role === ADMIN_ROLE &&
        <Grid container spacing={2} className="mb-3" alignItems="center">
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="User Email Address"
              value={filterName.emailAddress}
              onChange={handleEmailChange}
              name="emailAddress"
              id="emailAddress"
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              inputProps ={{
                pattern: "^[0-9]",
                min: "1",
                step: "1"
              }}
              id="vehicleId"
              type='number'
              label="Vehicle ID"
              value={filterName.vehicleId}
              onChange={handleVehicleId}
            />
          </Grid>
        </Grid>
      }
      {
        (role === DEALER_ROLE || role === ADMIN_ROLE) &&
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              id="make"
              select
              placeholder="Please select"
              label="Filter Make"
              value={filterName.make}
              onChange={(event, child) => handleMakeChange(event, child)}
            >
              {make.map((option) => (
                <MenuItem key={option.id} id={option.id} value={option.manufacturerName}>
                  {option.manufacturerName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={2}>

            <TextField
              fullWidth
              id="model"
              select
              placeholder="Please select"
              label="Filter Model"
              value={filterName.model}
              disabled={disabled}
              onChange={(event, child) => handleModelChange(event, child)}
            >
              {model.map((option) => (
                <MenuItem key={option.id} id={option.id} value={option.modelName}>
                  {option.modelName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={2}>
            <Autocomplete
              id="location"
              value={locationValue}
              open={openLocation}
              onOpen={() => {
                setOpenLocation(true);
              }}
              onClose={() => {
                setOpenLocation(false);
              }}
              onChange={handleLocationChange}
              getOptionSelected={(option, value) => {
                return option.city === value.city
              }}
              getOptionLabel={(option) => option.city}
              options={locations}
              loading={loadingLocation}
              onInputChange={(event, newLocationQuery) => {
                setLocationQuery(newLocationQuery);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />

          </Grid>

          <Grid item xs={6} md={4}>
            {
              (Object.values(filterName).some((filter) => filter !== '' ) || locationQuery ) &&
              <Button
                onClick={clearFilters}
                startIcon={<CancelIcon />}
              >
                Clear Filters
              </Button>
            }
          </Grid>
          <Grid item xs={6} md={2} className="d-flex justify-content-end align-items-center">
            <Grid item className="mr-2">
              Sort By Date
            </Grid>
            <Grid item className="d-flex flex-column">
              <IconButton aria-label="asc"
                          className="p-0"
                          onClick={() => setSortDirection('Asc')}
                          size="small">
                <ArrowDropUpIcon fontSize="inherit" />
              </IconButton>
              <IconButton aria-label="desc"
                          className="p-0"
                          onClick={() => setSortDirection('Desc')}
                          size="small">
                <ArrowDropDownIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      }
      </div>
      <Grid container spacing={2}>
        {
          vehicles.length > 0 &&
          <React.Fragment>
            {
              vehicles.map((vehicle, index) => {
                  if (vehicles.length === index + 1) {
                    return <Grid item xs={12} key={vehicle.id} ref={lastVehicleRef}>
                      <CarCard vehicle={vehicle} openVehicle={navigateToVehicle} />
                    </Grid>
                  }
                  return <Grid item xs={12} key={vehicle.id}>
                    <CarCard vehicle={vehicle} openVehicle={navigateToVehicle} />
                  </Grid>
                }
              )
            }
          </React.Fragment>
        }
        {
          (vehicles.length === 0 && !loading) &&
          <Grid container className="pl-2">
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" color="textSecondary">
                {role === DEALER_ROLE ? 'No vehicles available in your area':'You have no listed vehicles'}
              </Typography>
            </Grid>
          </Grid>
        }
        { loading &&
        <Grid container className="mt-5" alignItems="center" justify="center">
          <Grid item>
            <CircularProgress size={80} />
          </Grid>
        </Grid>
        }
      </Grid>
    </Grid>
      <PaymentConfirmation
        paymentStatus={paymentStatus}
        open={openPaymentDialog}
        closeDialog={()=>setPaymentDialog(false)} />
    </React.Fragment>
  );
}
