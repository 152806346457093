import React, { useContext } from 'react';
import {Route, Redirect} from 'react-router-dom';
import {AUTH_USER} from './Constants';
import {AuthContext} from './Contexts/AuthContext'

export default function AuthenticatedRoute({ component: Component, ...rest }){
  let authenticatedUser = localStorage.getItem(AUTH_USER);
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={props =>
        (authenticatedUser || isAuthenticated) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}
