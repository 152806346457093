class ImageService {

 getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      }
      reader.onerror = error => {
        reject(error);
      }
    });
  }

  parseBase64(url) {
    return url.split(',')[1];
  }
}

export default new ImageService()