import React, { useState, createContext, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {AUTO_DISMISS} from '../Constants'
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export const AlertContext = createContext()

export function AlertProvider({ children }) {
  const [alerts, setAlerts] = useState([])
  const [open, setOpen] = useState(false)
  const [delay, setDelay] = useState(AUTO_DISMISS)

  const activeAlertIds = alerts.join(',')
  useEffect(() => {
    if (activeAlertIds.length > 0) {
      const timer = setTimeout(() => {
        setOpen(false)
        setAlerts((alerts) => alerts.slice(0, alerts.length - 1))
      }, delay)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAlertIds])

  const addAlert = (alert, delay = AUTO_DISMISS) => {
    setDelay(delay)
    setOpen(true)
    setAlerts((alerts) => [alert, ...alerts])
  }

  const handleClose = () => {
    setOpen(false);
  };

  const value = { addAlert }

  return (
    <AlertContext.Provider value={value}>
      {children}
      {alerts.map((alert, index) =>
        (<Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        message={alert}
        key={index}
        action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
        }
        />)
      )}
    </AlertContext.Provider>
  )
}