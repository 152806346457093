export const AUTH_USER = 'user';
export const TMP_USER = 'tmpUser';
export const VEHICLE = 'UnsavedVehicle';
export const SELLER_ROLE = 'GeneralUser';
export const DEALER_ROLE = 'Dealer';
export const ADMIN_ROLE = 'Administrator';
export const BASE_URI = process.env.REACT_APP_API_BASE_URL;
export const ACTIVE_STATE = 'Active';
export const CONCLUDING_STATE = 'Concluding';
export const SOLD_STATE = 'Sold';
export const DRAFT_STATE = 'Draft';
export const AUTO_DISMISS = 5000;
export const VEHICLE_ADDONS = ['spare keys','service book', 'maintenance plan', 'warranty'];
export const PAGE_SIZE = 5;
export const NEW_LISTING_PAYMENT_AMOUNT = 50;
export const LISTING_COUNT_INC = 'one'
export const PAYMENT_INC_LISTING_TYPE = 'IncreaseListingCap';
export const POLLING_DELAY = 60000
export const IMAGE_UPLOADER_CONFIG = {
  maxWidth: 300,
  maxHeight: 300,
  compressFormat: 'JPEG',
  quality: 100,
  rotation: 0,
  outputType: 'base64'
}