import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import CardHeader from '@material-ui/core/CardHeader'
import Image from 'material-ui-image'
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  green: {
    backgroundColor: '#488c20'
  },
  white: {
    color: '#FFF'
  },
  fadedWhite: {
    color: 'rgba(255,255,255,0.5)'
  },
  tag: {
    padding: ' 5px 10px',
    backgroundColor: 'transparent',
    border: '2px solid white',
    borderRadius: '5px'
  }
});

export default function ReviewCard({review, openVehicle}) {
  const classes = useStyles();
  const {id, seller, rating, imageUrl,tags} = review

  return (
    <Card className={classes.green}>
      <CardActionArea onClick={()=>openVehicle(id)}>
          <CardHeader className={classes.white}
            title={
              <div className='text-capitalize'>{seller.toLowerCase()}</div>
            }
            subheader={
              <Rating name="read-only"
                      className={clsx('mt-2', classes.white)}
                      value={rating} readOnly
                      emptyIcon={<StarBorderIcon className={classes.fadedWhite} fontSize="inherit" />}
              />
            }
          />
          <Image src={imageUrl} key={id} aspectRatio={(16/9)} color='lightgrey'/>
          <CardContent className='pb-3'>
            <Grid className="my-2" container alignItems='center' spacing={1}>
              {
                tags.map((tag) => (
                  <Grid item key={tag.id}>
                    <div className={classes.tag}>
                      <Typography className={clsx("font-weight-light text-capitalize",classes.white)}>
                        {tag.name.toLowerCase()}
                      </Typography>
                    </div>
                  </Grid>
                ))
              }
            </Grid>
          </CardContent>
      </CardActionArea>
    </Card>
  )
}