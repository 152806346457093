import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { StyledDialogTitle } from '../shared/StyledDialogs'


export default function HelpDialog({ open, closeDialog }) {

    return (
        <Dialog maxWidth='xs' onClose={closeDialog} open={open}>
            <StyledDialogTitle onClose={closeDialog}>
                More Info
      </StyledDialogTitle>
            <DialogContent>
                <Typography variant="h6" gutterBottom>
                    Fees
        </Typography>
                <ul>
                    <li>When a customer accepts your bid, we charge you a lead fee of R400</li>
                    <li>
                        Commission payable on successful purchase of vehicle from customer is 1% of purchase price. The lead fee will be credited against the commission.
                    </li>
                </ul>
            </DialogContent>
            <DialogActions className="py-3 px-3">
                <Button color="secondary" onClick={closeDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    )

}