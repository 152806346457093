import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { StyledDialogTitle} from '../../shared/StyledDialogs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(() => ({
  lightOrange: {
    color: '#f7b624'
  },
  darkOrange: {
    color: '#f4a44b'
  }
}));

export default function EditListingDialog({action, bidCount, open, closeDialog}) {
  const localStyles = useStyles();

  return (
    <Dialog maxWidth='xs' onClose={closeDialog}  open={open}>
      <StyledDialogTitle onClose={closeDialog}>
        Edit Listing
      </StyledDialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          {
            bidCount > 0  ?
              `Valuation might be affected if you edit now`
              :
              `Do you wish to edit the current listing?`
          }
        </Typography>
        {
          bidCount > 0 &&
            <Grid container className="my-3">
              <Grid item>
                <WarningIcon className={localStyles.lightOrange}/>
                <Typography variant="caption" className="pl-2" color="textSecondary" component="span">
                  Currently <span className={localStyles.darkOrange}><strong>{bidCount} Active Bids</strong></span>
                </Typography>
              </Grid>
            </Grid>
        }
      </DialogContent>
      <DialogActions className="py-3 px-3">
        <Button color="secondary" onClick={closeDialog}>Cancel</Button>
        <Button variant="contained" onClick={()=>action('edit')} color="primary">
          Edit Listing
        </Button>
      </DialogActions>
    </Dialog>
  )

}
