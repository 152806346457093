import React, {useState, useContext}  from "react";
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from '@material-ui/core/styles/index'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton'
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import {LoadingContext} from '../shared/Contexts/LoadingContext'
import {AlertContext} from '../shared/Contexts/AlertContext'
import Image from 'material-ui-image'
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import {BASE_URI, PAGE_SIZE} from '../shared/Constants'
import Grid from '@material-ui/core/Grid'
import TableHeadersSort from '../shared/TableHeadersSort'
import UserFilter from '../shared/UserFilter'
import ConfirmationDialog from '../shared/ConfirmationDialog'
import axios from 'axios'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import UserDialog from './UserDialog'


const useStyles = makeStyles(() => ({
  tableHeader: {
    borderBottom: '2px solid darkgray'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}));

export default function UserContainer() {
  const localStyles = useStyles();
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [anchorRef, setAnchorRef] = useState(null);
  const [activeUser, setActiveUser] = useState({
    id: '',
    name: '',
    action: ''
  });
  const [filter, setFilter] = useState('');
  const { addAlert } = useContext(AlertContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [userDialog, setUserDialog] = useState(false);

  const{
    loading,
    users,
    total
  }= UserFilter(filter, page, pageSize)

  const handleMenuToggle = (event, id, firstName, lastName, email, lockedOut) => {
    setActiveUser({
      id,
      name: `${firstName} ${lastName}`,
      email,
      lockedOut
    })
    setAnchorRef(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  }

  const handleMenuClose = () => {
    setOpen(false);
    if (anchorRef) {
      setAnchorRef(null);
    }
  }

  const openDialog =(action) => {
    setActiveUser({...activeUser, action: action})
    setOpenConfirm(true)
    handleMenuClose()
  }

  const performUserAction = () => {
    setOpenConfirm(false)
    showLoading()
    switch (activeUser.action) {
      case 'block':
      {
        blockUser().then(() => {
          hideLoading()
          addAlert(`${activeUser.lockedOut ? 'Unblocked': 'Blocked'} ${activeUser.name}`)
        }).catch((error) => {
          hideLoading()
          if (error.response?.data.error) {
            addAlert(error.response?.data.error)
          } else {
            addAlert(`Something went wrong ${activeUser.lockedOut ? 'blocking' : 'unblocking'} ${activeUser.name}`)
          }
        })
        break;
      }
      case 'reset password for':
      {
        processResetPasswordRequest().then(() => {
          hideLoading()
          addAlert(`Password request sent to ${activeUser.email}`)
        }).catch((error) => {
          hideLoading()
          if (error.response?.data.error) {
            addAlert(error.response?.data.error)
          } else {
            addAlert(`Something went wrong resetting ${activeUser.name} password`)
          }
        })
        break;
      }
      case 'delete':
      {
        deleteUser().then(() => {
          hideLoading()
          addAlert(`Successfully deleted ${activeUser.email}`)
        }).catch((error) => {
          hideLoading()
          if (error.response?.data.error) {
            addAlert(error.response?.data.error)
          } else {
            addAlert(`Something went wrong deleting ${activeUser.name}`)
          }
        })
        break;
      }
      default:
      {
        addAlert(`Unable to ${activeUser.action} `)
        break;
      }
    }
  }

  const processResetPasswordRequest = () => {
    return axios.post(`${BASE_URI}/Identity/ForgotPasswordRequest`, {
      email: activeUser.email
    })
  }

  const blockUser = () => {
    return axios.post(`${BASE_URI}/User/`, null, {
      params: {
        userId: activeUser.id
      }
    })
  }

  const deleteUser = () => {
    return axios.delete(`${BASE_URI}/User/`, {
      params: {
        userId: activeUser.id
      }
    })
  }

  const addUser = (role) => {
    setActiveUser({
      firstName: '',
      lastName: '',
      idNumber: '',
      role: role,
      city: '',
      emailAddress:'',
      mobileNumber: '',
      driversLicense: '',
      password: '',
      profilePicture: ''
    })
    setUserDialog(true)
  }


  const imageContainer = {
    height: 40,
    width: 40,
    paddingTop: 0,
    marginRight: 20
  }

  const profileImage = {
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    borderRadius: '50%'
  }

  const filterEmailAddress = (event) => {
    setFilter(event.target.value)
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headCells = [
    { id: 'firstName', numeric: false, width: '15%', label: 'Name', sort: false },
    { id: 'emailAddress', numeric: false, label: 'Email', sort: false },
    { id: 'idNumber', numeric: false, label: 'ID', sort: false },
    { id: 'mobileNumber', numeric: false, label: 'Mobile Number', sort: false },
    { id: 'city', numeric: false, label: 'City', sort: false },
    { id: 'province', numeric: false, label: 'Province', sort: false },
    { id: 'numAvailableListings', numeric: false, label: 'Number of Available Listings', sort: false },
    { id: 'action', numeric: false, label: ' ', sort: false },
  ];


  return (
    <Card className="mt-4">
      <CardHeader title='Users' action={
        <React.Fragment>
          <TextField
            label="User Email Address"
            value={filter}
            onChange={filterEmailAddress}
            name="emailAddress"
            id="emailAddress"
            className="mr-lg-4"
          />
          <Button onClick={() => addUser('GeneralUser')} className="m-2"
                  variant="contained"
                  startIcon={<AddIcon />}
                  color="primary">
            User
          </Button>
          <Button onClick={() => addUser('Dealer')} className="m-2"
                  variant="contained"
                  startIcon={<AddIcon />}
                  color="primary">
            Dealer
          </Button>
        </React.Fragment>
       } />
      <TableContainer component={Paper}>
        <ConfirmationDialog user={activeUser.name}
                        action={activeUser.action}
                        open={openConfirm}
                        confirm={performUserAction}
                        closeDialog={()=>setOpenConfirm(false)} />
        <UserDialog     user={activeUser}
                        closeDialog={() => setUserDialog(false)}
                        open={userDialog}/>
        <Table aria-label="bids table" size="small">
          <TableHeadersSort
            classes={localStyles}
            headCells={headCells}
          />
          <TableBody>
            {
              users.map((row) => (
                <Tooltip title={`Blocked`} disableHoverListener={!row.lockedOut} placement="left">
                <TableRow key={row.id} >
                  <TableCell>
                    <Grid container spacing={0} alignItems="center">
                      {
                        row.profilePicture &&
                          <Grid item>
                            <Image
                              imageStyle={profileImage}
                              style={imageContainer}
                              src={row.profilePicture} alt={`${row.firstName} ${row.lastName}`}/>
                          </Grid>
                      }
                      <Grid item container
                            style={{color: row.lockedOut ? 'red' : 'inherit'}}
                            className='text-capitalize'>
                          <Typography>
                            {row.firstName} {row.lastName}
                          </Typography>
                        <Typography variant="caption">
                          {row.role}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell style={{color: row.lockedOut ? 'red' : 'inherit'}}>
                    {row.emailAddress}
                  </TableCell>
                  <TableCell style={{color: row.lockedOut ? 'red' : 'inherit'}}>{row.idNumber}</TableCell>
                  <TableCell style={{color: row.lockedOut ? 'red' : 'inherit'}}>{row.mobileNumber}</TableCell>
                  <TableCell style={{color: row.lockedOut ? 'red' : 'inherit'}}>{row.city}</TableCell>
                  <TableCell style={{color: row.lockedOut ? 'red' : 'inherit'}}>{row.province}</TableCell>
                  <TableCell style={{color: row.lockedOut ? 'red' : 'inherit'}}>{row.numAvailableListings}</TableCell>
                  <TableCell className="pr-0"  style={{width: '5%'}}>
                    <IconButton aria-label="options"
                                onClick={(e) => handleMenuToggle(e, row.id, row.firstName, row.lastName, row.emailAddress, row.lockedOut)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </Tooltip>
              ))}
            {
              ((!users.length || users.length === 0) && !loading) &&
              <TableRow>
                <TableCell colSpan={8} align="center"
                           style={{borderBottom: 0}}
                           className="py-3">
                  <Typography variant="h6" color="textSecondary">
                    No users listed
                  </Typography>
                </TableCell>
              </TableRow>
            }
            {
              loading &&
              <TableRow>
                <TableCell colSpan={8} align="center"
                           style={{borderBottom: 0}}
                           className="py-3">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
        <Popper open={open} anchorEl={anchorRef} role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleMenuClose}>
                  <MenuList id="split-button-menu">
                    <MenuItem onClick={() => openDialog('block')}>
                      {activeUser.lockedOut ? 'Unblock': 'Block'}
                    </MenuItem>
                    <MenuItem onClick={() => openDialog('delete')}>
                      Delete
                    </MenuItem>
                    <MenuItem onClick={() => openDialog('reset password for')}>
                      Reset Password
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <TablePagination
          component="div"
          count={total}
          page={page}
          rowsPerPageOptions={[5,10,25]}
          onChangePage={handleChangePage}
          rowsPerPage={pageSize}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Card>
  )
}
