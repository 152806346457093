import React, { useEffect, useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import TextMaskCustom from '../../shared/TextMask';
import GlobalStyles from '../../GlobalStyles';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import ImageUploader from '../../shared/ImageUploader'
import ImageService from '../../shared/ImageService'
import LocationFilter from '../../shared/LocationFilter'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BASE_URI, IMAGE_UPLOADER_CONFIG, TMP_USER, VEHICLE } from '../../shared/Constants'
import axios from 'axios'
import { AlertContext } from '../../shared/Contexts/AlertContext'
import { LoadingContext } from '../../shared/Contexts/LoadingContext'
import Resizer from "react-image-file-resizer"

export default function PersonalDetailsForm({ onSubmit, user, modalView, closeDialog }) {
    const classes = GlobalStyles();
    const { addAlert } = useContext(AlertContext)
    const [openLocation, setOpenLocation] = React.useState(false);
    const { showLoading, hideLoading } = useContext(LoadingContext)
    const [values, setValues] = React.useState({
        firstName: '',
        role: 'GeneralUser',
        emailAddress: '',
        mobileNumber: '',
    });
    const [errors, setError] = React.useState({
        firstName: false,
        emailAddress: false,
        mobileNumber: false,
    })
    const [invalid, setInvalid] = React.useState(true)

    useEffect(() => {
        if (user) {
            setValues(user)

        }
    }, [user])


    useEffect(() => {
        const hasErrors = Object.keys(errors).some(k => errors[k]);
        const hasEmptyValues = Object.keys(values).some(k => values[k] === '');
        if (hasErrors || hasEmptyValues) {
            if (hasEmptyValues && values.role === 'Dealer' && values.driversLicense === '') {
                setInvalid(false)
            } else {
                setInvalid(true)
            }
        } else {
            setInvalid(false)
        }

    }, [values, errors])

    const {
        loadingLocation,
        locations
    } = LocationFilter(values.city)

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const checkError = (prop) => (event) => {
        if (event.target.value === '') {
            setError({ ...errors, [prop]: true })
        } else {
            setError({ ...errors, [prop]: false })
        }
    }

    const submitForm = () => {
        const newErrors = Object.fromEntries(
            Object.entries(values).map(
                ([key, val]) => {
                    if (key === 'emailAddress' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val) && val !== '') {
                        return [key, true]
                    } else {
                        return [key, val === '']
                    }
                }
            )
        );

        setError(newErrors)

        console.log(newErrors);

        if (!invalid) {
            showLoading()
            if (values.role !== 'Dealer') {

                createUser().then((response) => {
                    const tmpSeller = {
                        token: response.data?.token,
                        refreshToken: response.data?.refreshToken
                    }

                    localStorage.setItem(TMP_USER, JSON.stringify(tmpSeller))

                    createCar().then((vehicleResponse) => {
                        hideLoading()
                        onSubmit(vehicleResponse.data)
                    }).catch((error) => {
                        hideLoading()
                        if (error.response) {
                            addAlert(error.response.data.error)
                        } else {
                            addAlert('Error creating vehicle, please contact Admin')
                        }
                    });

                }).catch((error) => {
                    hideLoading()
                    if (error.response) {
                        addAlert(error.response.data.error)
                    } else {
                        addAlert('Error creating user, please contact Admin')
                    }
                })
            } else {
                createUser().then((response) => {
                    hideLoading()
                    onSubmit('added')
                }).catch((error) => {
                    hideLoading()
                    if (error.response) {
                        addAlert(error.response.data.error)
                    } else {
                        addAlert('Error creating user, please contact Admin')
                    }
                })
            }
        }
    }

    const createCar = () => {
        return axios.post(`${BASE_URI}/vehicle`, JSON.parse(localStorage.getItem(VEHICLE)))
    }

    const createUser = () => {
        let tmpValues = { ...values }

        return axios.post(`${BASE_URI}/User`, tmpValues)
    }

    const validateEmail = () => {
        setError({ ...errors, emailAddress: values.emailAddress === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress) })
    }

    return (
        <React.Fragment>
            {
                modalView ?
                    <React.Fragment>
                        <form className="p-3" noValidate autoComplete="off">
                            <Grid container spacing={4}>

                                {/*@TODO main form*/}
                                <Grid item xs={12} sm={6} container direction="column">
                                    <TextField
                                        className='mb-2'
                                        fullWidth
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onBlur={checkError('firstName')}
                                        error={errors.firstName}
                                        helperText={errors.firstName ? `Please enter ${values.role !== 'Dealer' ? 'Name & Surname' : 'Dealer Name'}` : ' '}
                                        id="firstName"
                                        label={values.role !== 'Dealer' ? "Name & Surname" : "Dealer Name"}
                                        value={values.firstName}
                                        onChange={handleChange('firstName')}
                                    />

                                    <TextField
                                        className='mb-2'
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Mobile Number"
                                        required
                                        value={values.mobileNumber}
                                        onChange={handleChange('mobileNumber')}
                                        onBlur={checkError('mobileNumber')}
                                        error={errors.mobileNumber}
                                        helperText={errors.mobileNumber ? 'Please enter mobile number' : ' '}
                                        name="mobileNumber"
                                        id="mobileNumber"
                                        InputProps={{
                                            inputComponent: TextMaskCustom,
                                        }}
                                    />

                                    <TextField
                                        className='mb-2'
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Email Address"
                                        required
                                        value={values.emailAddress}
                                        onChange={handleChange('emailAddress')}
                                        onBlur={validateEmail}
                                        error={errors.emailAddress}
                                        helperText={errors.emailAddress ? 'Please enter a valid email' : ' '}
                                        name="emailAddress"
                                        id="emailAddress"
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        <Grid container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                            <Button color="secondary" className="mr-3" onClick={closeDialog}>Cancel</Button>
                            <Button variant='contained' onClick={submitForm} color="primary">
                                Submit
              </Button>
                        </Grid>
                    </React.Fragment>
                    :
                    <Card>
                        <CardHeader className="p-4"
                            title={'About you'}
                            subheader={'In order to load your car we need to know a bit about you.'}
                        />
                        <CardContent className="px-4">
                            <form className="p-3" noValidate autoComplete="off">
                                <Grid container spacing={4}>

                                    {/*@TODO main form*/}
                                    <Grid item xs={12} sm={4} container direction="column">
                                        <TextField
                                            className='mb-2'
                                            fullWidth
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onBlur={checkError('firstName')}
                                            error={errors.firstName}
                                            helperText={errors.firstName ? 'Please enter Name & Surname' : ' '}
                                            id="firstName"
                                            label="Name & Surname"
                                            value={values.firstName}
                                            onChange={handleChange('firstName')}
                                        />

                                        <TextField
                                            className='mb-2'
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Mobile Number"
                                            required
                                            value={values.mobileNumber}
                                            onChange={handleChange('mobileNumber')}
                                            onBlur={checkError('mobileNumber')}
                                            error={errors.mobileNumber}
                                            helperText={errors.mobileNumber ? 'Please enter mobile number' : ' '}
                                            name="mobileNumber"
                                            id="mobileNumber"
                                            InputProps={{
                                                inputComponent: TextMaskCustom,
                                            }}
                                        />

                                        <TextField
                                            className='mb-2'
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Email Address"
                                            required
                                            value={values.emailAddress}
                                            onChange={handleChange('emailAddress')}
                                            onBlur={validateEmail}
                                            error={errors.emailAddress}
                                            helperText={errors.emailAddress ? 'Please enter a valid email' : ' '}
                                            name="emailAddress"
                                            id="emailAddress"
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                        <CardActions className={classes.greyBackground}>
                            <Grid container justify="flex-end">
                                <Button variant='contained'

                                    onClick={submitForm} color="primary">
                                    Next
                </Button>
                            </Grid>
                        </CardActions>
                    </Card>
            }
        </React.Fragment>

    );
}
