import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import useIntersectionObserver from './useIntersectionObserver'
import Image from 'material-ui-image'

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    width: '100%'
  }
}));

export const ImageContainer = props => {
  const ref = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);
  const classes = useStyles();

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    }
  });

  return (
    <div
      ref={ref}
      className={classes.imageContainer}>
      {isVisible && (
        <Image src={props.src} alt={props.alt}
               imageStyle={{
                 height: 'initial',
                 bottom: 0,
                 margin: 'auto'
               }}
               aspectRatio={(16/9)} color='lightgrey'/>
      )}
    </div>
  );
};
